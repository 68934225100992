$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


.contact {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $blackHue90;
    z-index: 200;
    padding: 120px 3vw 100px 3vw;
    overflow: hidden !important;

    .yellow-helmet {
        position: absolute;
        bottom: 15%;
        right: 0;
        width: 800px;
    }
    
    .title-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

    h3 {
        color: $mainBlack;
        font-weight: 700;
        position: relative;
        text-align: left;
        padding: 0;

        &::after {
            content: '';
            position: absolute;
            bottom: -0.5rem;
            left: 0;
            background-color: $blackHue40;
            height: 3px;
            width: 40px;
        }
    }

        h5 {
            color: $mainBlack;
            font-weight: 300;
            text-align: left;
            padding: 0;
            margin: 20px 0 80px 0;
        }
    }

    .form {
        display: flex;
        justify-content: space-between;
        position: relative;

        .form-container {
            padding: 0 1.5rem;

            .dependers {
                display: flex;
                flex-direction: row;

                .random {
                    padding: 0 1rem 0 0;
                }

                .business {
                    padding: 0 0 0 1rem
                }
            }

            hr {
                background-color: $blackHue70;
            }

            .split {
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .left {
                    padding: 0 1rem 0 0;
                }

                .right {
                    padding: 0 0 0 1rem;
                }
            }

            .miniGDPR {
                color: $blackHue70;
                font-weight: 300;
                font-style: italic;
            }

            .submitActions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: auto;

                .buttonContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    #loader {
                        width: 22px;
                        height: 22px;
                        background: transparent;
                        font-size: 22px;
                        color: $mainBlue;
                        margin-right: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transform: rotate(360deg);
                        transform-origin: center;
                        animation: rotation 1.5s infinite linear;
                    }
          
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }
                        
                        to {
                            transfrom: rotate(359deg);
                        }
                    }

                    .submitForm {
                        border: none;
                        outline: none;
                        background: transparent;
                        color: $blackHue70;
                        padding: 5px 30px 5px 0;
                        position: relative;
                        font-weight: 700;
                        font-size: 22px;
                        user-select: none;
        
                        .arrow {
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: flex-end;
                            top: 55%;
                            right: 0px;
                            background-color: $blackHue70;
                            width: 20px;
                            height: 2px;
                            border-radius: 2px;
        
        
                            &::before {
                                content: '';
                                position: absolute;
                                background-color: $blackHue70;
                                width: 10px;
                                height: 2px;
                                border-radius: 2px;
                                transform-origin: top right;
                                transform: rotate(45deg);
                            }
        
                            &::after {
                                content: '';
                                position: absolute;
                                background-color: $blackHue70;
                                width: 10px;
                                height: 2px;
                                border-radius: 2px;
                                transform-origin: bottom right;
                                transform: rotate(-45deg);
                            }
                        }
                    }
        
                    .submitForm.active {
                        border: none;
                        outline: none;
                        background: transparent;
                        color: $mainBlue;
                        padding: 5px 30px 5px 0;
                        position: relative;
                        font-weight: 700;
                        font-size: 22px;
                        opacity: 1;
                        transition: opacity 0.3s ease;
                        transition-delay: 0.25s;
        
                        .arrow {
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: flex-end;
                            top: 55%;
                            right: 0px;
                            background-color: $mainBlue;
                            width: 20px;
                            height: 2px;
                            border-radius: 2px;
                            opacity: 1;
                            transition: transform 0.3s ease-in-out;
        
        
                            &::before {
                                content: '';
                                position: absolute;
                                background-color: $mainBlue;
                                width: 10px;
                                height: 2px;
                                border-radius: 2px;
                                transform-origin: top right;
                                transform: rotate(45deg);
                                transition: transform 0.3s ease-in-out;
                            }
        
                            &::after {
                                content: '';
                                position: absolute;
                                background-color: $mainBlue;
                                width: 10px;
                                height: 2px;
                                border-radius: 2px;
                                transform-origin: bottom right;
                                transform: rotate(-45deg);
                                transition: transform 0.3s ease-in-out;
                            }
                        }
        
                        &:hover {
        
                            .arrow {
                                transform: translateX(8px);
                                transition: transform 0.3s ease-in-out;
                            }
                        }
                    }
                }

                .successMessage {
                    margin: 10px 0 0 0;
                    padding: 0;
                    color: green;
                    font-weight: 400;
                    font-style: italic;
                }
            }
        }
    }
}
