$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow: #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 100px 3vw;


@media (max-width: 1400px) {

    .footer {

        .mainContainer {

            .logoBox {
                padding-right: 15px !important; 
            }
        }
    }
}


@media (max-width: 1199px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;
            display: flex;
            justify-content: space-between;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 110px;
                    top: -40px;
                }

                .address {
                    margin-top: 65px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }
            }

            .language {
                padding-left: 15px !important;
                margin: 0;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 150px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 6vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 50px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 30px;
                }

                .services,
                .projects {
                    display: none;
                }

                .wrapper {
                    margin: 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1026px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;
            display: flex;
            justify-content: space-between;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 110px;
                    top: -40px;
                }

                .address {
                    margin-top: 65px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 150px;
                    width: 700px;
                    height: 700px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 700px;
                            width: 700px !important;
                        }
                    }

                    .xButton {
                        top: 10px !important;
                        right: 10px !important;
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin: 0;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 150px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 6vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 50px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 25px;
                }

                .services,
                .projects {
                    display: none;
                }

                .wrapper {
                    margin: 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 811px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;
            display: flex;
            justify-content: space-between;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 110px;
                    top: -40px;
                }

                .address {
                    margin-top: 65px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 130px;
                    width: 550px;
                    height: 600px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -50px;
                            height: 600px;
                            width: 600px !important;
                        }
                    }

                    .xButton {
                        top: 10px !important;
                        right: 10px !important;
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin: 0;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 130px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 6vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 50px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 20px;
                }

                .services,
                .projects {
                    display: none;
                }

                .wrapper {
                    margin: 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 767px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;
            display: flex;
            justify-content: space-between;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 110px;
                    top: -40px;
                }

                .address {
                    margin-top: 65px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 130px;
                    width: 550px;
                    height: 600px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -50px;
                            height: 600px;
                            width: 600px !important;
                        }
                    }

                    .xButton {
                        top: 10px !important;
                        right: 10px !important;
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin: 0;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 130px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 6vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 50px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .title {
                    text-align: start;
                    margin-bottom: 20px;
                }

                .services,
                .projects {
                    display: flex;
                }

                .wrapper {
                    margin: 40px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 700px) {

    .footer {

        .mainContainer {

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 20px;
                }

                .services,
                .projects {
                    display: flex;
                }

                .wrapper {
                    margin: 40px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 550px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 110px;
                    top: -40px;
                }

                .address {
                    margin-top: 65px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 40px;
                    width: 400px;
                    height: 600px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -60px;
                            height: 600px;
                            width: 600px !important;
                        }

                        .xButton {
                            top: 10px !important;
                            right: 10px !important;
                        }
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin-bottom: 30px;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 130px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 10vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 50px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 20px;
                }

                .wrapper {
                    margin: 40px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 415px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 0px !important;

                .icon {
                    font-size: 100px;
                    top: -35px;
                }

                .address {
                    margin-top: 60px;
                }

                .footerMapBtn {
                    padding: 5px 20px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 20px;
                    width: 380px;
                    height: 600px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -60px;
                            height: 600px;
                            width: 600px !important;
                        }

                        .xButton {
                            top: 20px !important;
                            right: 0px !important;
                        }
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin-bottom: 30px;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 130px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 10vw;

                    .smIcon {
                        margin: 5px 0 0 30px !important;
                    
                        .fab {
                            font-size: 40px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 20px;
                }

                .wrapper {
                    margin: 40px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 376px) {

    h6 {
        font-size: 20px !important;
    }

    button {
        font-size: 18px !important;
    }

    .footer {

        .mainContainer {
            padding: 60px 3vw 30px 3vw;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 10px !important;

                .icon {
                    font-size: 90px;
                    top: -28px;
                }

                .address {
                    margin-top: 60px;
                }

                .footerMapBtn {
                    padding: 3px 14px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 30px;
                    width: 360px;
                    height: 550px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -80px;
                            height: 580px;
                            width: 580px !important;
                        }

                        .xButton {
                            top: 20px !important;
                            right: 0px !important;
                        }
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin-bottom: 30px;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 130px;
                    margin: 0 0 30px 0;
                }

                .smIcons {
                    margin-top: 10vw;

                    .smIcon {
                        margin: 5px 0 0 20px !important;
                    
                        .fab {
                            font-size: 40px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 15px;
                }

                .wrapper {
                    margin: 30px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 321px) {

    .footer {

        .mainContainer {
            padding: 50px 3vw 30px 3vw;

            .logoBox {
                margin-bottom: 30px;
                padding-right: 15px !important;

                .icon {
                    font-size: 80px;
                    top: -28px;
                }

                .address {
                    margin-top: 45px;
                }

                .footerMapBtn {
                    padding: 3px 14px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 60px;
                    width: 310px;
                    height: 450px !important;
 
                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -80px;
                            height: 520px;
                            width: 520px !important;
                        }

                        .xButton {
                            top: 20px !important;
                            right: 0px !important;
                        }
                    }
                }
            }

            .language {
                padding-left: 15px !important;
                margin-bottom: 30px;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 100px;
                    margin: 0;
                }

                .smIcons {

                    .smIcon {
                        margin: 5px 0 0 20px !important;
                    
                        .fab {
                            font-size: 40px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 15px;
                }

                .wrapper {
                    margin: 30px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 280px) {

    .footer {

        .mainContainer {
            padding: 50px 3vw;

            .logoBox {
                margin-bottom: 30px;

                .icon {
                    font-size: 80px;
                    top: -28px;
                }

                .address {
                    margin-top: 45px;
                }

                .footerMapBtn {
                    padding: 3px 14px;
                    margin-top: 30px;
                }

                .mapModal {
                    margin-top: 80px;
                    width: 270px;
                    height: 500px !important;

                    .modalBody {
                        position: relative;

                        .mapImage {
                            position: absolute;
                            top: 0;
                            right: -80px;
                            height: 520px;
                            width: 520px !important;
                        }
                    }
                }
            }

            .language {
                margin-bottom: 30px;

                .title {
                    text-align: end;
                }

                .selectContainer {
                    width: 100px;
                    margin: 0;
                }

                .smIcons {

                    .smIcon {
                        margin: 0 0 0 20px !important;
                    
                        .fab {
                            font-size: 40px;
                        }
                    }
                }
            }

            .linkBox {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .title {
                    text-align: start;
                    margin-bottom: 15px;
                }

                .wrapper {
                    margin: 30px 0 0 0;

                    .listItems {
                        margin: 0;

                        .listItem {
                            margin-bottom: 10px;

                            .modalLink {
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}