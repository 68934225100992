$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow: #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;



@media (max-width: 1400px) {
    
    .home {

        .brandLogo {
            left: 1vw;
        }

        .verticalSlide {
            position: absolute;
            left: -3.5vw;
            font-weight: 400;
        }
    
        .imageContainer {
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    color: $mainBlack;
    
                    &::after {
                        background-color: $blackHue40;
                        width: 40px;
                    }
                }
            }
        }


        .servicesSection {

            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .allServices {
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 100px 0;

                    .imgFramer {
                        margin: 0;
                    }

                    .overlayer {
                        top: 90px;
                        padding: 15px;

                        h4 {
                            font-size: 1.3rem;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }
        }



        .orangeSection {

            .home-illustration {
                width: 750px;
                height: auto;
                position: absolute;
                bottom: -4%;
                right: -8%;
              }

            .textSection {

                .subtitle {
                    margin-bottom: 90px;
                }

                .wrapper {
                    margin-top: 40px;

                    .icon {
                        font-size: 65px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-weight: 400;
                            margin-bottom: 30px;
                        }

                        p {
                            font-weight: 300;
                        }
                    }
                }
            }
        }


        .industries {
            
            .wrapper {
                display: flex;
                justify-content: flex-start;

                .title-container {
                    position: unset;
                    margin-bottom: 40px;
                    display: flex;
                    justify-content: center;
    
                    .title {
                        text-align: center !important;
                    }
                }

                .case {

                    .icon {
                        font-size: 75px;
                    }

                    .titleDescr {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }
        }


        .yellowSection {

            .textSection {

                .globe-cartoon {
                    bottom: 2%;
                    right: 0%;
                    width: 650px;
                    height: auto;
                }
            }
        }

        .contact {
            position: relative;

            .yellow-helmet {
                width: 700px;
                height: auto;
                position: absolute;
                right: 0;
                bottom: 15%;
            }
        }
    }
}




@media (max-width: 1200px) {

    a {
        font-size: 18px;
    }

    .home {

        .brandLogo {
            left: 0vw;
            top: 150px;
            width: 500px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 890px;
            left: 87vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 1000px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    color: $mainBlack;
    
                    &::after {
                        background-color: $blackHue40;
                        width: 40px;
                    }
                }
    
                .quoteButton {
                    padding: 14px 55px 15px 30px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 25px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 50px 3vw 80px 3vw;
            position: relative;

            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0 0 30px 0;

                h3 {
                    text-align: left;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    font-weight: 400;
                    margin-bottom: 0.5rem;

                    &::after {
                        width: 40px;
                    }
                }

                .allServices {
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 100px 0;

                    .imgFramer {
                        margin: 0;
                    }

                    .overlayer {
                        top: 100px;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 70px 3vw 90px 0;

            .home-illustration {
                width: 550px;
                height: auto;
                position: absolute;
                bottom: -4%;
                right: -8%;
              }

            .textSection {

                .subtitle {
                    padding-right: 5px;
                    line-height: 30px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 55px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 24px;
                            font-weight: 400;
                            margin-bottom: 20px;
                        }

                        p {
                            font-size: 18px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 60px 3vw 80px 3vw;
            position: relative !important;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;

            .title-container {
                position: unset;
                margin-bottom: 40px;
                display: flex;
                justify-content: center;

                .title {
                    text-align: center !important;
                }
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 65px;
                        padding-left: 10px;
                    }

                    .titleDescr {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }
        }



        .projects {
            padding: 60px 3vw 90px 3vw;
            position: relative;

            .title-container {
                margin: 0 0 50px 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .allProjects {
                    font-size: 18px;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 20px;

                        .title {

                            span {
                                font-size: 16px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }



        .yellowSection {
            padding: 70px 3vw 100px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -5%;
                    right: 0%;
                    width: 550px;
                    height: auto;
                }

                .intro {
                    font-size: 26px;
                    line-height: 32px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 100px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            height: 90px !important;
                            width: 90px;
                            overflow: hidden;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;
                }

                .reasons {

                    .reason {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon {   
                            height: 80px;
                            width: auto;
                            font-size: 70px;
                        }

                        .label {
                            font-size: 26px;
                            text-align: left;
                            padding: 0 20px 15px 20px;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 60px 3vw 90px 3vw;
            position: relative;

            .yellow-helmet {
                position: absolute;
                bottom: 15%;
                right: -2%;
                width: 600px;
            }

            .title-container {

                h5 {
                    margin: 20px 0 70px 0;
                }
            }

            .form {
                position: relative;
                
                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .random {
                            padding: 0 1rem 0 0;
                        }
                
                        .business {
                            padding: 0 0 0 1rem
                        }
                    }

                    .split {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;

                        .left {
                            padding: 0 1rem 0 0;
                        }
            
                        .right {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 1026px) {

    .home {

        .brandLogo {
            left: -1vw;
            top: 230px;
            width: 450px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 790px;
            left: 87vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 900px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    font-size: 40px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 40px 3vw 50px 3vw;
            position: relative;
            background-position: 75% 30% !important;

            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0 0 30px 0;

                h3 {
                    font-size: 28px;
                    text-align: left;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    font-weight: 400;
                    margin-bottom: 0.5rem;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 100px 0;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 285px;
                            height: 190px !important;
                        }
                    }

                    .overlayer {
                        top: 100px !important;
                        padding: 20px !important;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }
        }



        .orangeSection {
            padding: 50px 3vw 70px 0;

            .home-illustration {
                width: 500px;
                height: auto;
                position: absolute;
                bottom: -4%;
                right: -8%;
              }

            .textSection {

                h1 {
                    font-size: 34px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 30px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 45px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 50px 3vw 60px 3vw;
            position: relative !important;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;

            .title-container {
                position: unset;
                margin-bottom: 40px;
                display: flex;
                justify-content: center;

                .title {
                    text-align: center !important;
                }
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        padding-left: 17px;
                        font-size: 50px;
                        text-align: end;
                    }

                    .titleDescr {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 60px 3vw;
            position: relative;

            .title-container {
                margin: 0 0 50px 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    font-size: 18px;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 15px !important;

                        .title {

                            span {
                                font-size: 14px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }



        .yellowSection {
            padding: 40px 3vw 80px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -3%;
                    right: -2%;
                    width: 500px;
                    height: auto;
                }

                .intro {
                    font-size: 22px;
                    line-height: 32px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 100px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            height: 70px !important;
                            width: 70px;
                            overflow: hidden;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 30px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .whyUs {
                    margin-bottom: 40px !important;

                    .title {
                        font-size: 34px !important;
                    }
                }

                .reasons {

                    .reason {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon {   
                            height: 60px;
                            width: auto;
                            font-size: 60px;
                        }

                        .label {
                            font-size: 24px;
                            text-align: left;
                            padding: 0 20px 0 20px;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;
            position: relative;

            .yellow-helmet {
                position: absolute;
                bottom: 12%;
                right: -2%;
                width: 550px;
            }

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .form {
                position: relative;

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .random {
                            padding: 0 1rem 0 0;
                        }
                
                        .business {
                            padding: 0 0 0 1rem
                        }
                    }

                    .split {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;

                        .left {
                            padding: 0 1rem 0 0;
                        }
            
                        .right {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 991px) {

    .contact {
        position: relative;

        .yellow-helmet {
            display: none !important;
        }
    }
}



@media (max-width: 811px) {

    .home {

        .brandLogo {
            left: -2vw;
            top: 230px;
            width: 350px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 690px;
            left: 84vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 800px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    font-size: 40px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 40px 3vw 60px 3vw;
            position: relative;

            .title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0 0 30px 0;

                h3 {
                    font-size: 28px;
                    text-align: left;
                    position: relative;
                    padding: 0;
                    margin: 0;
                    font-weight: 400;
                    margin-bottom: 0.5rem;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 100px 0;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 212px;
                            height: 141px !important;
                        }
                    }

                    .overlayer {
                        top: 75px !important;
                        padding: 15px !important;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 50px 3vw 300px 0;

            .home-illustration {
                width: 460px;
                height: auto;
                position: absolute;
                bottom: -310px;
                right: 110px;
              }

            .textSection {

                h1 {
                    font-size: 32px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 30px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 45px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 50px 3vw 60px 3vw;
            position: relative !important;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;

            .title-container {
                position: unset;
                margin-bottom: 40px;
                display: flex;
                justify-content: center;

                .title {
                    text-align: center !important;
                }
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 55px;
                        padding: 0;
                    }

                    .titleDescr {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 60px 3vw;
            position: relative;

            .title-container {
                margin: 0 0 50px 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    font-size: 18px;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 30px;
                    text-align: end;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 20px;

                        .title {

                            span {
                                font-size: 14px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 80px 0;

            .textSection {

                .globe-cartoon {
                    bottom: 6%;
                    right: -3%;
                    width: 340px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 30px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 90px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            height: 60px !important;
                            width: 60px;
                            overflow: hidden;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .whyUs {
                    margin-bottom: 30px !important;

                    .title {
                        font-size: 32px !important;
                    }
                }

                .reasons {

                    .reason {

                        .icon {   
                            height: 70px;
                            width: auto;
                            font-size: 55px;
                        }

                        .label {
                            font-size: 24px;
                            text-align: left;
                            padding: 0 10px 15px 10px;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .random {
                            padding: 0 1rem 0 0;
                        }
                
                        .business {
                            padding: 0 0 0 1rem
                        }
                    }

                    .split {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;

                        .left {
                            padding: 0 1rem 0 0;
                        }
            
                        .right {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 541px) {

    .home {

        .brandLogo {
            left: -2vw;
            top: 180px;
            width: 250px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 590px;
            left: 78vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 700px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    font-size: 40px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 40px 3vw 80px 3vw;
            position: relative;

            .title-container {
                flex-direction: column;
                margin: 0 0 20px 0;
                position: unset;

                h3 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    margin-top: 25px;
                    font-size: 18px;
                    width: 43%;
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    padding: 0;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 45px 0 !important;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 456px;
                            height: 304px !important;
                        }
                    }

                    .overlayer {
                        top: 180px;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .expertise,
            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 50px 3vw 300px 0;

            .home-illustration {
                width: 440px;
                height: auto;
                position: absolute;
                bottom: -300px;
                right: 10px;
              }

            .textSection {

                h1 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 30px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 35px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 60px 3vw 70px 3vw;
            position: relative !important;

            .title-container {
                position: unset;
                margin-bottom: 40px;

                .title {
                    text-align: center;
                }
            }

            .introText {
                margin: 20px 0 20px 0;
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 75px;
                        padding: 0;
                    }

                    .titleDescr {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 100px 3vw;
            position: relative;

            .title-container {
                position: unset;
                margin: 0 0 30px 0;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 15px !important;

                        .title {

                            span {
                                font-size: 14px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 400px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -26%;
                    right: 60px;
                    width: 380px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 36px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 100px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            height: 60px !important;
                            width: 60px;
                            overflow: hidden;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .whyUs {
                    margin-bottom: 35px !important;

                    .title {
                        font-size: 32px;
                    }
                }

                .reasons {

                    .reason {

                        .icon {   
                            height: 70px;
                            width: auto;
                            font-size: 65px;
                        }

                        .label {
                            font-size: 24px;
                            text-align: left;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: column;

                        .random,
                        .business {
                            padding: 0;
                        }
                    }

                    .split {
                        display: flex;
                        flex-direction: column;

                        .left,
                        .right {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 415px) {

    .home {

        .brandLogo {
            left: -3vw;
            top: 155px;
            width: 200px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 490px;
            left: 73vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 600px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    font-size: 36px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 30px 3vw 90px 3vw;
            position: relative;

            .title-container {
                flex-direction: column;
                margin: 0 0 20px 0;
                position: unset;

                h3 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    margin-top: 25px;
                    font-size: 18px;
                    width: 55%;
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    padding: 0;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 80px 0 !important;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 348px;
                            height: 232px !important;
                        }
                    }

                    .overlayer {
                        top: 110px !important;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .expertise,
            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 40px 3vw 250px 0;

            .home-illustration {
                width: 400px;
                height: auto;
                position: absolute;
                bottom: -260px;
                right: -40px;
              }

            .textSection {

                h1 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 30px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 35px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 50px 3vw 60px 3vw;
            position: relative !important;

            .title-container {
                position: unset;
                margin-bottom: 40px;

                .title {
                    text-align: center;
                }
            }

            .introText {
                margin: 20px 0 20px 0;
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 65px;
                        padding: 0;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 100px 3vw;
            position: relative;

            .title-container {
                position: unset;
                margin: 0 0 30px 0;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 10px;

                        .title {

                            span {
                                font-size: 14px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }

                    .projectImg {
                        position: relative !important;
    
                        .picture {
                            position: absolute !important;
                            left: -100% !important;
                            bottom: 0;
                        }
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 350px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -20%;
                    right: 30px;
                    width: 300px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 33px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 100px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            height: 50px !important;
                            width: 50px;
                            overflow: hidden;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .whyUs {
                    margin: 0 0 40px 0;

                    .title {
                        font-size: 30px !important;
                    }
                }

                .reasons {

                    .reason {

                        .icon {   
                            height: 70px;
                            width: auto;
                            font-size: 65px;
                        }

                        .label {
                            font-size: 24px;
                            text-align: left;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: column;

                        .random,
                        .business {
                            padding: 0;
                        }
                    }

                    .split {
                        display: flex;
                        flex-direction: column;

                        .left,
                        .right {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 376px) {

    .home {

        .brandLogo {
            left: -4vw;
            top: 155px;
            width: 200px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 490px;
            left: 70vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 600px;
            width: 100%;
            background-size: cover;
            background-position: 65% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    margin-right: 40px;
                    font-size: 34px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 30px 3vw 65px 3vw;
            position: relative;

            .title-container {
                flex-direction: column;
                margin: 0 0 20px 0;
                position: unset;

                h3 {
                    font-size: 26px;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    margin-top: 25px;
                    font-size: 18px;
                    width: 61%;
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    padding: 0;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 70px 0 !important;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 314px;
                            height: 232px !important;
                        }
                    }

                    .overlayer {
                        top: 90px;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .expertise,
            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 40px 3vw 250px 0;

            .home-illustration {
                width: 400px;
                height: auto;
                position: absolute;
                bottom: -260px;
                right: -50px;
              }

            .textSection {

                h1 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 25px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 35px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 40px 3vw 60px 3vw;
            position: relative !important;

            .title-container {
                position: unset;
                margin-bottom: 40px;

                .title {
                    text-align: center;
                }
            }

            .introText {
                margin: 20px 0 20px 0;
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 60px;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 100px 3vw;
            position: relative;

            .title-container {
                position: unset;
                margin: 0 0 30px 0;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 10px;

                        .title {

                            span {
                                font-size: 14px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }

                .projectImg {
                    position: relative !important;

                    .picture {
                        position: absolute !important;
                        left: -100% !important;
                        bottom: 0;
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 350px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -18%;
                    right: 10px;
                    width: 300px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 33px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 60px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            width: 80px;
                            height: 80px !important;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .whyUs {
                    margin: 0 0 40px 0;

                    .title {
                        font-size: 28px;
                    } 
                }

                .reasons {

                    .reason {

                        .icon {   
                            height: 70px;
                            width: auto;
                            font-size: 65px;
                        }

                        .label {
                            font-size: 24px;
                            text-align: left;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: column;

                        .random,
                        .business {
                            padding: 0;
                        }
                    }

                    .split {
                        display: flex;
                        flex-direction: column;

                        .left,
                        .right {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}





@media (max-width: 321px) {

    .home {

        .brandLogo {
            left: -4vw;
            top: 155px;
            width: 200px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 490px;
            left: 65vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 600px;
            width: 100%;
            background-size: cover;
            background-position: 60% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    font-size: 34px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 48%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 30px 3vw 60px 3vw;
            position: relative;

            .title-container {
                flex-direction: column;
                margin: 0 0 20px 0;
                position: unset;

                h3 {
                    font-size: 26px;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    margin-top: 25px;
                    width: 71%;
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    padding: 0;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 70px 0 !important;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 267px;
                            height: auto;
                        }
                    }

                    .overlayer {
                        top: 90px;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .expertise,
            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 40px 3vw 250px 0;

            .home-illustration {
                width: 400px;
                height: auto;
                position: absolute;
                bottom: -260px;
                right: -80px;
              }

            .textSection {

                h1 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 25px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 30px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 40px 3vw 60px 3vw;
            position: relative !important;

            .title-container {
                position: unset;
                margin-bottom: 30px;

                .title {
                    text-align: center;

                }
            }

            .introText {
                margin: 20px 0 20px 0;
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 50px;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 70px 3vw;
            position: relative;

            .title-container {
                position: unset;
                margin: 0 0 30px 0;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 10px !important;

                        .title {

                            span {
                                font-size: 12px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }

                    .projectImg {
                        position: relative !important;
    
                        .picture {
                            position: absolute !important;
                            left: -125% !important;
                            bottom: 0;
                        }
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 350px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -20%;
                    right: -20px;
                    width: 300px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 33px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 60px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            width: 75px;
                            height: 75px !important;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .title {
                    font-size: 28px;
                }

                .reasons {

                    .reason {

                        .icon {
                            width: auto;
                            font-size: 60px;
                        }

                        .label {
                            font-size: 20px;
                            text-align: left;
                            padding: 0 !important;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: column;

                        .random,
                        .business {
                            padding: 0;
                        }
                    }

                    .split {
                        display: flex;
                        flex-direction: column;

                        .left,
                        .right {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}





@media (max-width: 280px) {

    a {
        font-size: 18px !important;
    }
    
    button {
        font-size: 18px !important;
    }

    .home {

        .brandLogo {
            left: -4vw;
            top: 155px;
            width: 200px;
            height: auto;
        }

        .verticalSlide {
            position: absolute;
            top: 490px;
            left: 60vw;
            font-weight: 700;
            color: $mainWhite;
        }
    
        .imageContainer {
            height: 600px;
            width: 100%;
            background-size: cover;
            background-position: 67% 50%;
            background-repeat: no-repeat;
    
            .landingPage {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
    
                .mainTitle {
                    font-size: 34px;
                    color: $mainWhite;
    
                    &::after {
                        background-color: $mainWhite;
                        width: 30px;
                    }
                }
    
                .quoteButton {
                    padding: 9px 45px 10px 20px;
                    border-radius: 3px;
                    background-color: $mainWhite !important;
                    position: relative;
                    box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                    -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
                    .arrow {
                        position: absolute;
                        top: 48%;
                        right: 15px;
                    }
                }
            }
        }
        
        .servicesSection {
            padding: 30px 3vw 60px 3vw;
            position: relative;

            .title-container {
                flex-direction: column;
                margin: 0 0 20px 0;
                position: unset;

                h3 {
                    font-size: 26px;

                    &::after {
                        width: 30px;
                    }
                }

                .allServices {
                    margin-top: 25px;
                    width: 76%;
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    padding: 0;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 0.3rem;

                .serviceCard {
                    margin: 10px 0 140px 0;

                    .imgFramer {
                        margin: 0;

                        .imgCard {
                            width: 252px !important;
                            height: auto;
                        }
                    }

                    .overlayer {
                        top: 90px;
                        padding: 25px;

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .resp {
                    margin: 10px 0 100px 0;
                }
            }

            .expertise,
            .arialSurveying {
                display: none;
            }
        }



        .orangeSection {
            padding: 40px 3vw 250px 0;

            .home-illustration {
                width: 400px;
                height: auto;
                position: absolute;
                bottom: -260px;
                right: -80px;
              }

            .textSection {

                h1 {
                    font-size: 28px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    padding-right: 5px;
                    line-height: 25px;
                    margin-bottom: 70px;
                }

                .wrapper {
                    margin-top: 20px;

                    .icon {
                        font-size: 30px;
                        padding-top: 5px;
                    }

                    .text {

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }



        .industries {
            padding: 40px 3vw 50px 3vw;
            position: relative !important;

            .title-container {
                position: unset;
                margin-bottom: 30px;

                .title {
                    text-align: center;
                }
            }

            .introText {
                margin: 20px 0 20px 0;
            }

            .wrapper {
                display: flex;
                justify-content: flex-start;
                margin: 0;

                .case {
                    margin: 20px 0;

                    .icon {
                        font-size: 50px;
                    }
                }
            }
        }



        .projects {
            padding: 40px 3vw 100px 3vw;
            position: relative;

            .title-container {
                position: unset;
                margin: 0 0 30px 0;

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                .allProjects {
                    position: absolute;
                    bottom: 40px;
                    left: 3vw;
                    font-size: 18px;
                }
            }

            .wrapper {
                padding: 10px;
                margin-bottom: 20px;

                .project {
                    position: relative;

                    .titleDescr {
                        padding: 10px;

                        .title {

                            span {
                                font-size: 12px;
                            }
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }

            .resp {
                display: none;
            }
        }



        .yellowSection {
            padding: 40px 3vw 350px 0;

            .textSection {

                .globe-cartoon {
                    bottom: -20%;
                    right: -20px;
                    width: 300px;
                    height: auto;
                }

                .intro {
                    font-size: 20px;
                    line-height: 30px;
                    padding-right: 5px;
                }

                .partners {
                    margin-bottom: 60px;

                    .partners-title {
                        margin-bottom: 20px;
                    }

                    .partnerLogos {
                        display: flex;
                        justify-content: space-around;
                        margin: 0;

                        .logo {
                            width: 75px;
                            height: 75px !important;
                        }
                    }
                }

                .title-container {
                    margin: 0 0 30px 0;

                    h1 {
                        font-size: 28px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .reasons {

                    .reason {

                        .icon {   
                            height: 70px;
                            width: auto;
                            font-size: 60px;
                        }

                        .label {
                            font-size: 22px;
                            text-align: left;
                        }
                    }

                    .aboutBtn {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }



        .contact {
            padding: 40px 3vw 50px 3vw;

            .title-container {

                h3 {

                    &::after {
                        width: 30px;
                    }
                }

                h5 {
                    margin: 20px 0 50px 0;
                }
            }

            .yellow-helmet {
                display: none;
            }

            .form {

                .form-container {
                    padding: 0 0.5rem;

                    .dependers {
                        display: flex;
                        flex-direction: column;

                        .random,
                        .business {
                            padding: 0;
                        }
                    }

                    .split {
                        display: flex;
                        flex-direction: column;

                        .left,
                        .right {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}