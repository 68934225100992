$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


@media (max-width: 1200px) {

    .modalWrapper {
        max-width: 900px !important;
        height: auto;
        margin-top: 1vh !important;

        .modalHeader {
            width: 100%;
            height: 350px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 900px !important;
                height: auto;
            }

            .cadasterImg {
                width: 900px !important;
                margin-left: 0;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 1026px) {

    .modalWrapper {
        max-width: 800px !important;
        height: auto;
        margin-top: 100px !important;

        .modalHeader {
            width: 100%;
            height: 320px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 850px !important;
                height: auto;
            }

            .cadasterImg {
                width: 850px !important;
                margin-left: 20px;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 811px) {

    .modalWrapper {
        width: 600px !important;
        height: auto;
        margin-top: 40px !important;

        .modalHeader {
            width: 100%;
            height: 270px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 700px !important;
                height: auto;
            }

            .cadasterImg {
                width: 750px !important;
                margin-left: 20px;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -3vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 770px) {

    .modalWrapper {
        width: 600px !important;
        height: auto;
        margin-top: 40px !important;

        .modalHeader {
            width: 100%;
            height: 270px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 700px !important;
                height: auto;
            }

            .cadasterImg {
                width: 750px !important;
                margin-left: 20px;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -3vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 550px) {

    .modalWrapper {
        width: 500px !important;
        height: auto;
        margin-top: 60px !important;

        .modalHeader {
            width: 100%;
            height: 270px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 700px !important;
                height: auto;
            }

            .cadasterImg {
                width: 750px !important;
                margin-left: 20px;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -3vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 415px) {

    .modalWrapper {
        width: 400px !important;
        height: auto;
        margin-top: 60px !important;

        .modalHeader {
            width: 100%;
            height: 250px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 600px !important;
                height: auto;
            }

            .cadasterImg {
                width: 650px !important;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -3vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 376px) {

    .modalWrapper {
        width: 360px !important;
        height: auto;
        margin-top: 50px !important;
        margin-top: 60px !important;

        .modalHeader {
            width: 100%;
            height: 250px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 600px !important;
                height: auto;
            }

            .cadasterImg {
                width: 650px !important;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -3vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 28px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 34px !important;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 321px) {

    .modalWrapper {
        width: 305px !important;
        height: auto;
        margin-top: 60px !important;

        .modalHeader {
            width: 100%;
            height: 250px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 600px !important;
                height: auto;
            }

            .cadasterImg {
                width: 650px !important;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: -2vw;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 24px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 30px;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}


@media (max-width: 280px) {

    .modalWrapper {
        width: 270px !important;
        height: auto;
        margin-top: 60px !important;

        .modalHeader {
            width: 100%;
            height: 250px;
            position: relative;
            display: flex;
            justify-content: center;

            .img {
                width: 600px !important;
                height: auto;
            }

            .cadasterImg {
                width: 650px !important;
            }

            .xButton {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .modalBody {

            .title {
                margin-bottom: 30px;
            }

            .descr {
                margin-bottom: 30px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0 0 20px 0 !important;

                .element {
                    margin: 10px 0;

                    .icon {
                        font-size: 24px !important;
                    }

                    .icon-lika,
                    .icon-dahlem {
                        font-size: 30px;
                    }

                    .subject {
                        width: auto;
                    }
                }
            }
        }

        .modalFooter {
            padding: 3vh 3vw 3vh 3vw;

            .exit {
                font-size: 18px !important;
            }
        }
    }
}