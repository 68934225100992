// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


//Styling

.services {
    position: relative;
    background: transparent;
    overflow: hidden;

    .servicesHeader {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        height: 600px;
        padding: $boxPadding;
        background: url(/images/servicesHeader.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 47%;
        z-index: 500;

        .title-container {
            margin-top: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;

            .title {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                margin-bottom: 30px;
                
                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }

            .subtitle {
                font-weight: 300;
                line-height: 30px;
                color: $mainWhite;
            }
        }
    }


    .ourServices {
        position: relative;
        height: auto;
        width: 100%;
        padding: 70px 3vw 80px 3vw;
        background-color: $yellowHue10;

        .all-services {
            background: transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .wrapper {
                padding: 3vh 2vw;

                .btn {
                    background: transparent;
                    padding: 0;
                    margin: 0;
                    outline: none;
                    border: none;

                    .imgFramer {
                        border-radius: 3px;
                        overflow: hidden;
                        position: relative;
                        padding: 0;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                        -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
                        -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);

                        &::after {
                            content: '';
                            width: 100%;
                            height: 0;
                            background-color: $mainOrange;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            z-index: 100;
                            transition: all 0.2s ease;
                        }
    
                        .imgCard {
                            width: 100%;
                            height: auto;
                            z-index: 1;
                            transition: all 0.3s ease;
                        }

                        .overlayer {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            height: 25%;
                            z-index: 5;
                            background-color: rgba($color: $mainBlack, $alpha: 0.7);

                            .title {
                                color: $mainWhite;
                                text-align: start;
                                margin-left: 30px;
                                padding-right: 40px;
                                text-transform: capitalize;
                                letter-spacing: 2px;
                                font-weight: 700;
                                position: relative;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    bottom: -0.1rem;
                                    left: 0;
                                    background-color: $blackHue90;
                                    height: 3px;
                                    width: 40px;
                                }

                                .arrow {
                                    position: absolute;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    align-items: flex-end;
                                    top: 50%;
                                    right: 0px;
                                    background-color: $mainOrange;
                                    width: 20px;
                                    height: 2px;
                                    border-radius: 2px;
                                    opacity: 0;
                                    transition: all 0.2s ease-in-out;
                          
                          
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        background-color: $mainOrange;
                                        width: 10px;
                                        height: 2px;
                                        border-radius: 2px;
                                        transform-origin: top right;
                                        transform: rotate(45deg);
                                    }
                          
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        background-color: $mainOrange;
                                        width: 10px;
                                        height: 2px;
                                        border-radius: 2px;
                                        transform-origin: bottom right;
                                        transform: rotate(-45deg);
                                    }
                                }
                            }
                        }

                        &:focus,
                        &:hover {

                            &::after {
                                height: 5px;
                            }

                            .imgCard {
                                transform: scale(1.1);
                                opacity: 0.8;
                            }

                            .overlayer {

                                .title {

                                    .arrow {
                                        transform: translateX(10px);
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .howItWorks {
        position: relative;
        padding: 90px 3vw 100px 3vw;
        background-color: $blackHue90;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 100px;
            background-color: $blackHue40;
            top: 90px;
            right: 3vw;
        }

        .title-container {
            color: $mainBlack;
            font-weight: 700;
            text-align: start;
            margin-bottom: 40px;

            .theTitle {
                color: $mainBlack;
                font-weight: 700;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue40;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }
        }

        .wrapper {
            padding: 3vh 5vw;

            .theStep {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .picture {
                    width: 250px;
                    height: 250px;
                    margin-bottom: 30px;
                }

                .title {
                    color: $mainBlack;
                    font-weight: 700;
                    margin-bottom: 20px;
                    text-align: center;
                }

                .subtitle {
                    color: $mainBlack;
                    font-weight: 300;
                    font-style: italic;
                    text-align: center;
                }
            }
        }
    }

    .ourCadaster {
        position: relative;
        padding: 40px 3vw 50px 3vw;
        background-color: $blackHue90;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;
        flex-wrap: wrap !important;
        height: auto;

        .text-wrapper {

            .descr {
                color: $mainBlack;
                font-weight: 300;
                font-style: italic;
                text-align: start;
            }
        }

        .imgWrapper {
            height: auto;
            width: 100%;

            .picture {
                width: 500px;
                height: auto;
            }
        }
    }


    .allServices {
        position: relative;
        background-color: $mainOrange;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 100px 3vw 100px 3vw;

        .title-container {
            color: $mainWhite;
            font-style: italic;
            text-align: center;
            margin-bottom: 100px;
        }

        .entities {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            height: auto;

            .business,
            .person {
                height: auto;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .category {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-start;

                    .categoryTitle {
                        color: $mainWhite;
                        font-weight: 700;
                        text-align: start;
                        margin-bottom: 50px;
                        margin-left: 15px;
                        position: relative;
    
                        &::after {
                            content: '';
                            position: absolute;
                            background: $blackHue90;
                            bottom: -0.5rem;
                            left: 0;
                            width: 40px;
                            height: 3px;
                        }
                    }
    
                    .wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 15px;
                        margin-bottom: 15px;
    
                        .icon {
                            width: 90px;
                            height: 90px;
                            font-size: 90px;
                            background: transparent;
                            color: $mainWhite;
                            margin-right: 25px;
                        }
    
                        .descr {
                            color: $mainWhite;
                            font-weight: 400;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }

    .contactUs {
        width: 100%;
        height: auto;
        background-color: $mainBlack;
        padding: 100px 3vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .title {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                position: relative;
                margin-bottom: 20px;

                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }
    
            .subtitle {
                color: $mainWhite;
                font-weight: 300;
                font-style: italic;
            }
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .contact {
                position: relative;
                font-size: 18px;
                font-weight: 700;
                padding: 10px 55px 10px 30px;
                border-radius: 3px;
                color: $mainBlack;
                background-color: $mainYellow;
                transition: all 0.2s ease;
                
                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 20px;
                    background-color: $mainBlack;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
          
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }

    #divider {
        width: 100%;
        padding: $boxPadding;
        background-color: $mainBlack !important;
        z-index: 700;

        hr {
            background-color: $blackHue90;
            margin: 0;
        }
    }
}