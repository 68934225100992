$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


.about {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;

    .aboutHeader {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        height: 700px;
        padding: $boxPadding;
        background: url(/images/aboutHeader.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0%;
        z-index: 500;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;

            #ourStory {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                margin-bottom: 30px;
                
                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }

            #theStory {
                font-weight: 300;
                line-height: 30px;
                color: $mainWhite;
            }
        }
    }


    .partnerSection {
        position: relative;
        padding: 600px 3vw 60px 3vw;
        background-color: $mainYellow;
        height: auto;

        .breifStats {
            position: absolute;
            top: -90px;
            left: 8vw;
            border-radius: 5px;
            padding: 70px 3vw 50px 3vw !important;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            background-color: $blackHue90;
            z-index: 700;
            box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
            -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
            -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
        
            .wrapper {
                height: auto;
    
                .stat {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: center;
    
                    .pictureContainer {
                        width: 200px;
                        height: 200px;
                        background: transparent;
                        margin-bottom: 10px;
    
                        .picture {
                            width: 200px;
                            height: 200px;
                        }
                    }
    
                    .num {
                        font-weight: 700;
                        font-size: 60px;
                        text-align: center;
                        margin-bottom: 15px;
                        color: $mainBlack;
                    }
    
                    .descr {
                        font-weight: 300;
                        line-height: 40px;
                        text-align: center;
                        color: $mainBlack;
                    }
                }
            }
        }

        .descr {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: $mainBlack;
            margin-bottom: 60px;
        }

        .partners {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 30px 0;

            .wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .logo {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }



    .tools {
        position: relative;

        .title-container {
            padding: 100px 3vw 100px 3vw !important;
            background-color: $mainBlack;
            background: url(/images/tools-title.webp);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0%;

            .title {
                font-weight: 700;
                position: relative;
                margin-bottom: 20px;
                color: $mainWhite;
                text-align: start;

                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }

            .subtitle {
                font-weight: 300;
                color: $mainWhite;
                text-align: start;
                font-style: italic;
            }
        }

        .gray {
            background-color: $yellowHue10;
        }

        .white {
            background-color: $blackHue90 !important;
        }

        .theTool {
            padding: $boxPadding;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .descr {
                height: 400px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;

                .name {
                    font-weight: 700;
                    position: relative;
                    margin-bottom: 20px;
                    color: $mainBlack;

                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }

                .specification {
                    font-weight: 300;
                    color: $mainBlack;
                    font-style: italic;
                }
            }

            .wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .picture {
                    width: auto;
                    height: 380px;
                }

                .falconImg {
                    width: auto;
                    height: 340px;
                }
            }
        }
    }


    
    .whyUs {
        position: relative;
        padding: 100px 3vw 80px 3vw;
        background-color: $mainYellow;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .subtitle {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: $mainBlack;
            margin-bottom: 60px;
        }

        .wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            margin: 25px 0;
            width: auto;
            height: auto;

            .icon {
                font-size: 100px;
                color: $mainBlack;
                margin-bottom: 20px;
            }

            .label {
                width: 100%;
                text-align: center;
            }
        }
    }

    .vision {
        position: relative;
        width: 100%;
        height: auto;
        background-color: $blackHue90;
        padding: 100px 3vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        .g {
            position: absolute;
            left: -5%;
            top: 27%;
            width: 500px;
            height: 500px;
            opacity: 0.3;
        }

        .title-container {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 0;

            .title {
                font-weight: 700;
                font-style: italic;
                color: $blackHue40;
            }
        }

        .logoContainer {
            display: flex;
            justify-content: center;
            text-align: center;

            .icon-logo {
                position: relative !important;
                font-size: 320px;
                color: $mainBlue;
                text-shadow: 1px 2px 4px rgba($color: $mainBlack, $alpha: 0.4);

                .slider {
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 110px;
                    left: 0;
                    background: transparent;
                    width: 60%;
                    height: 26px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: flex-start;
                    text-shadow: none;
                    overflow: hidden;

                    .text1 {
                        bottom: 0;
                        left: 0;
                        height: 25px;
                        margin-bottom: 25px;
                        font-size: 28px;
                        font-weight: 700;
                        font-style: italic;
                        color: $blackHue40;
                        font-family: 'Exo 2', sans-serif;
                        letter-spacing: 2px;
                        box-sizing: border-box;
                        animation: slider 7s ease-in infinite;
                        transition-delay: 0s;
                    }

                    .text2 {
                        bottom: 0;
                        left: 0;
                        height: 25px;
                        margin-bottom: 25px;
                        font-size: 28px;
                        font-weight: 700;
                        font-style: italic;
                        color: $blackHue40;
                        font-family: 'Exo 2', sans-serif;
                        letter-spacing: 4px;
                        box-sizing: border-box;
                    }

                    .text3 {
                        bottom: 0;
                        left: 0;
                        height: 25px;
                        margin-bottom: 25px;
                        font-size: 28px;
                        font-weight: 700;
                        font-style: italic;
                        color: $blackHue40;
                        font-family: 'Exo 2', sans-serif;
                        letter-spacing: 3px;
                        box-sizing: border-box;
                    }

                    @keyframes slider {
                        0% {margin-top: -155px;}
                        15% {margin-top: -155px;}
                        25% {margin-top: -105px;}
                        40% {margin-top: -105px;}
                        50% {margin-top: -55px;}
                        65% {margin-top: -55px;}
                        75% {margin-top: -5px;}
                        90% {margin-top: -5px;}
                        100% {margin-top: 50px;}
                    }
                }
            }
        }

        .ourVision {
            text-align: center;
            color: $mainBlack;
            font-weight: 400;
        }
    }

    .contactUs {
        width: 100%;
        height: auto;
        background-color: $mainBlack;
        padding: 100px 3vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .title {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                position: relative;
                margin-bottom: 20px;

                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }
    
            .subtitle {
                color: $mainWhite;
                font-weight: 300;
                font-style: italic;
            }
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .contact {
                position: relative;
                font-size: 18px;
                font-weight: 700;
                padding: 10px 55px 10px 30px;
                border-radius: 3px;
                color: $mainBlack;
                background-color: $mainYellow;
                transition: all 0.2s ease;
                
                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 20px;
                    background-color: $mainBlack;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
          
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }

    #divider {
        width: 100%;
        padding: $boxPadding;
        background-color: $mainBlack !important;
        z-index: 700;

        hr {
            background-color: $blackHue90;
            margin: 0;
        }
    }
}
