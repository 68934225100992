$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow: #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


@media (max-width: 1025px) {

    .burgerButton {
        margin-top: 3px !important;
    }

    .upDrawer {

        .searchContainer {

            .search-box {

                .searchButton {

                    .icon-loop {
                        font-size: 28px !important;
                    }
                } 
            }
        }
    }
}


@media (max-width: 376px) {

    .theNav {

        .navBrand {
            width: 120px;
            height: 46px;

            .brandLogo {
                width: 110px;
                height: auto;
            }
        }
    }

    .upDrawer {

        .upDrawerToolbar {

            .navBrand__toggle.shown,
            .navBrand__toggle.hidden {

                width: 120px;
                height: 40px;

                .brandLogo {
                    width: 120px;
                    height: auto;
                }
            }
        }
    }
}



@media(max-width: 320px) {

    .theNav {

        .navBrand {
            padding: 0;
            width: 110px;
            height: 46px;

            .brandLogo {
                width: 110px;
                height: auto;
            }
        }

        .burgerButton {

            .line {
                width: 1.5em;

                &::before,
                &::after {
                    width: 1.8em;
                }
            }

            &:hover .line,
            &:focus .line {
                width: 1.8em;
            }
        }
    }



    .upDrawer {

        .upDrawerToolbar {
            
            .navBrand__toggle.shown,
            .navBrand__toggle.hidden {
                width: 110px;
                height: 40px;

                .brandLogo {
                    width: 110px;
                    height: auto;
                }
            }

            .closeSlideButton {

                .line__toggle {
                    width: 1.8em;

                    &::before {
                        width: 1.8em;
                    }
                } 
            }
        }

        .upDrawerNavItems {
            margin: 3rem 0;

            .navItem {

                .navLink {
                    font-size: 20px;
                }
            }
        }

        .searchContainer {

            .search-box {

                .searchBoxInput {
                    font-size: 18px;
                }

                .searchButton {
                    font-size: 26px;
                }
            }
        }
    }
}