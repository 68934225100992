$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


@media (max-width: 1200px) {

    .about {
        
        .aboutHeader {
            height: 710px;
            width: 100%;
            background-size: cover;
            background-position: 45% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 40px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 450px 3vw 70px 3vw;

            .breifStats {
                padding: 50px 3vw 40px 3vw !important;
                left: 4.3vw;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 170px;
                            height: 170px;

                            .picture {
                                width: 170px;
                                height: 170px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 0;

                    .logo {
                        width: 90px !important;
                        height: 90px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 60px 3vw 70px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 40px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: row;
                padding: 50px 3vw 50px 3vw;
                height: 400px !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 30px;

                        &::after {
                            width: 40px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 350px;
                        width: auto;
                    }

                    .falconImg {
                        height: 280px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 300px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 60px 3vw 70px 3vw;

            .subtitle {
                margin-bottom: 50px; 
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin: 22px 0;

                .icon {
                    font-size: 80px;
                    margin-bottom: 20px;
                }

                .label {
                    text-align: center;
                }
            }
        }

        .vision {
            padding: 60px 3vw 70px 3vw;

            .g {
                left: -10%;
                top: 10%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 240px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 60px 3vw 70px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title-container {
                margin: 0;

                .title {

                    &::after {
                        width: 40px;
                    }
                }
            }
        }
    }
}


@media (max-width: 1026px) {

    .about {
        
        .aboutHeader {
            height: 710px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 380px 3vw 50px 3vw;

            .breifStats {
                padding: 50px 3vw 40px 3vw !important;
                left: 4.3vw;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 140px;
                            height: 140px;

                            .picture {
                                width: 140px;
                                height: 140px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 0;

                    .logo {
                        width: 75px !important;
                        height: 75px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 50px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: row;
                padding: 50px 3vw 50px 3vw;
                height: 400px !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 350px;
                        width: auto;
                    }

                    .falconImg {
                        height: 280px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 300px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 50px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 22px 0;

                .icon {
                    font-size: 55px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 50px 3vw 50px 3vw;

            .g {
                left: -13%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 240px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 811px) {

    .about {
        
        .aboutHeader {
            height: 710px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 350px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4.3vw;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 140px;
                            height: 140px;

                            .picture {
                                width: 140px;
                                height: 140px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 0;

                    .logo {
                        width: 75px !important;
                        height: 75px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 350px;
                        width: auto;
                    }

                    .falconImg {
                        height: 300px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 310px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 18px 0;

                .icon {
                    font-size: 55px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -13%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 240px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 770px) {

    .about {
        
        .aboutHeader {
            height: 710px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 350px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4.3vw;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 140px;
                            height: 140px;

                            .picture {
                                width: 140px;
                                height: 140px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 0;

                    .logo {
                        width: 75px !important;
                        height: 75px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 350px;
                        width: auto;
                    }

                    .falconImg {
                        height: 300px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 310px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 18px 0;

                .icon {
                    font-size: 55px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -13%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 240px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 541px) {

    .about {
        
        .aboutHeader {
            height: 730px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 950px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 160px;
                            height: 160px;

                            .picture {
                                width: 160px;
                                height: 160px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 10px;

                    .logo {
                        width: 75px !important;
                        height: 75px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 310px;
                        width: auto;
                    }

                    .falconImg {
                        height: 250px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 260px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 18px 0;

                .icon {
                    font-size: 55px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -20%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 240px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 415px) {

    .about {
        
        .aboutHeader {
            height: 740px;
            width: 100%;
            background-size: cover;
            background-position: 55% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 980px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 160px;
                            height: 160px;

                            .picture {
                                width: 160px;
                                height: 160px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 10px;

                    .logo {
                        width: 70px !important;
                        height: 70px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 290px;
                        width: auto;
                    }

                    .falconImg {
                        height: 220px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 240px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 18px 0;

                .icon {
                    font-size: 55px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -30%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 220px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 376px) {

    .about {
        
        .aboutHeader {
            height: 740px;
            width: 100%;
            background-size: cover;
            background-position: 55% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 27px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 980px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 160px;
                            height: 160px;

                            .picture {
                                width: 160px;
                                height: 160px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 40px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 10px;

                    .logo {
                        width: 70px !important;
                        height: 70px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 270px;
                        width: auto;
                    }

                    .falconImg {
                        height: 200px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 210px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 18px 0;

                .icon {
                    font-size: 50px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -35%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 220px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 321px) {

    .about {
        
        .aboutHeader {
            height: 750px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 25px;
                    margin-bottom: 30px;
                }
            }
        }

        .partnerSection {
            padding: 930px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 150px;
                            height: 150px;

                            .picture {
                                width: 150px;
                                height: 150px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 30px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 10px;

                    .logo {
                        width: 60px !important;
                        height: 60px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 250px;
                        width: auto;
                    }

                    .falconImg {
                        height: 180px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 190px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 12px 0;

                .icon {
                    font-size: 50px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -50%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 220px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 280px) {

    .about {
        
        .aboutHeader {
            height: 750px;
            width: 100%;
            background-size: cover;
            background-position: 58% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 25px;
                }
            }
        }

        .partnerSection {
            padding: 930px 3vw 40px 3vw;

            .breifStats {
                padding: 40px 3vw 30px 3vw !important;
                left: 4vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .wrapper {

                    .stat {

                        .pictureContainer {
                            width: 150px;
                            height: 150px;

                            .picture {
                                width: 150px;
                                height: 150px;
                            }
                        } 
                    }

                    .num {
                        margin-bottom: 10px !important;
                    }

                    .descr {
                        line-height: 25px !important;
                        margin-bottom: 30px;
                    }
                }
            }

            .descr {
                margin-bottom: 30px;
            }

            .partners {
                margin: 0;

                .wrapper {
                    margin: 5px 10px;

                    .logo {
                        width: 60px !important;
                        height: 60px; 
                    }
                }
            }
        }

        .tools {
            position: relative;

            .title-container {
                padding: 40px 3vw 50px 3vw !important;
                background-size: cover;
                background-position: 75% 0%;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .theTool {
                display: flex;
                flex-direction: column;
                padding: 40px 3vw 40px 3vw;
                height: auto !important;

                .descr {
                    height: auto !important;
                    width: 100%;
                    margin-bottom: 20px;

                    .name {

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {

                    .picture {
                        height: 250px;
                        width: auto;
                    }

                    .falconImg {
                        height: 180px !important;
                        width: auto;
                    }

                    .sokkiaImg {
                        height: 190px !important;
                        width: auto;
                    }
                }
            }
        }

        .whyUs {
            padding: 40px 3vw 50px 3vw;

            .subtitle {
                margin-bottom: 30px; 
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 12px 0;

                .icon {
                    font-size: 50px;
                    margin: 0 10px 0 0;
                }

                .label {
                    margin: 0;
                    text-align: start;
                }
            }
        }

        .vision {
            padding: 40px 3vw 50px 3vw;

            .g {
                left: -70%;
                top: 5%;
                width: 500px;
                height: 500px;
            }

            .logoContainer {

                .icon-logo {
                    font-size: 200px;

                    .slider {
                        display: none;
                    }
                }
            }
        }


        //Contact Section

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}