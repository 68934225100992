// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 100px 3vw;


//Styling


.footer {
  background-color: $mainBlack;
  height: auto;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .mainContainer {
    padding: $boxPadding;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .logoBox {
      color: $mainWhite;
      display: block;
      letter-spacing: 2px;
      text-align: start;
      position: relative;

      .icon {
        font-size: 100px;
        position: absolute;
        top: -30px;
        left: 0;
        color: $blackHue90;

        &:hover {
          color: $mainWhite;
        }
      }

      .address {
        margin-top: 80px;
        line-height: 20px;
        color: $blackHue90;
        font-weight: 300;
      }

      .footerMapBtn {
        font-weight: 700;
        border: 1px solid $mainWhite;
        border-radius: 3px;
        background-color: transparent;
        padding: 5px 25px;
        margin: 28px 0 0 0;
        color: $mainWhite;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        position: relative;
      }
  
      .footerMapBtn span {
        z-index: 2;	
        display: block;
        width: 100%;
        height: 100%;
      }
  
      .footerMapBtn::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        background-color: rgba(255,255,255,0.5);
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        -webkit-transform: scale(0.5, 1);
        transform: scale(0.5, 1);
      }
  
      .footerMapBtn:hover::before {
        opacity: 1;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
      }
      
      .footerMapBtn:focus {
        border-radius: 0;
        outline: transparent;
      }

      .mapModal {
        position: relative;
        width: 1000px;
        height: 800px;
        background-color: $blackHue90;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        overflow: hidden;

        .modalBody {
          display: flex;
          position: relative;

          .mapImage {
            width: 800px;
            height: 800px;
          }

          .xButton {
            position: absolute;
            top: 10px;
            right: 15px;
            border: none;
            background: transparent;
            outline: none;
            color: $mainBlack;
            font-size: 34px !important;

            &:hover {
              color: $mainBlue;
            }
          }
        }
      }
    }
  
    .linkBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      letter-spacing: 2px;

      .title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 30px;
        color: $blackHue90;
      }

      .wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .listItems {
          list-style: none;
          padding: 0;
          text-align: start;
  
          .listItem {
            margin-bottom: 10px;
            width: auto;
            text-align: start;
      
            .modalLink {
              background: transparent;
              border: none;
              outline: none;
              padding: 0;
              color: $mainWhite;
              font-size: 20px;
              font-weight: 300;
              position: relative;
              transition: all 0.3s ease;
              overflow: hidden;

              &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: $yellowHue50;
                transition: all 0.2s ease-in-out;
              }

              a {
                color: $mainWhite;
              }

              &:focus,
              &:hover {
                color: $mainYellow;

                &::after {
                  transform: translateY(-4px);
                }
              }
            }
          }
        }
      }
    }
  
    .language {
      color: $mainWhite;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      letter-spacing: 2px;

      .title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 30px;
        color: $blackHue90;
      }
  
      .selectContainer {
        position: relative;
        width: 150px;
        display: flex;
        align-items: center;
        border-bottom: 0.1em solid $mainWhite;
        margin: 0 0 2rem 0;
  
        .chooseLang {
          font-size: 18px;
          border: none;
          outline: none;
          flex-grow: 1;
          color: $mainWhite;
          padding: 0 5px;
          background: transparent;
          appearance: none;
  
          option {
            color: $mainWhite;
            background: rgb(44, 44, 44);
            border: 0.1em solid $mainWhite;
          }
        }
  
        .costumArrow {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          pointer-events: none;
          background: transparent;
          color: $mainWhite;
          width: 24px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
  
      .smIcons {
        display: flex;
        justify-content: flex-end;
        align-items: bottom;
        margin-top: 6vh;
  
        .smIcon {
          margin-left: 1.5vw;
          color: $mainWhite;
  
          &:hover {
            color: $mainYellow;
          }

          .fab {
            font-size: 50px;
          }
        }
      }
    }
  }

  .copyrights {
    display: flex;
    padding: 0 3vw 15px 3vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .line {
      background-color: $blackHue90;
      width: 100%;
    }

    .endNote {
      color: $mainWhite;
      font-weight: 300;
    }
  }
}