$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;


@media (max-width: 1400px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 70px 3vw 70px 3vw;

            .all-services {

                .wrapper {
                    padding: 3vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 70px 3vw 100px 3vw;

            .title-container {
                margin-bottom: 30px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 0;

                .theStep {

                    .picture {
                        width: 230px;
                        height: 230px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 40px 3vw 50px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;
                margin-top: 30px;

                .picture {
                    width: 400px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 70px 3vw 70px 3vw;

            .title-container {
                margin-bottom: 40px;
            }

            .entities {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .category {
                        margin-left: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: flex-start;
                        padding: 30px;

                        .categoryTitle {
                            margin-bottom: 50px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 70px;
                                height: 70px;
                                font-size: 70px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 60px 3vw 70px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title-container {
                margin: 0;

                .title {

                    &::after {
                        width: 40px;
                    }
                }
            }
        }
    }
}


@media (max-width: 1200px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 70px 3vw 70px 3vw;

            .all-services {

                .wrapper {
                    padding: 2.5vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 70px 3vw 70px 3vw;

            .title-container {
                margin-bottom: 30px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 0;

                .theStep {

                    .picture {
                        width: 210px;
                        height: 210px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 40px 3vw 50px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;
                margin: 0;

                .picture {
                    width: 450px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 70px 3vw 70px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: center;

                    .category {
                        margin-left: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: flex-start;
                        padding: 30px;

                        .categoryTitle {
                            margin-top: 40px;
                            margin-bottom: 50px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 70px;
                                height: 70px;
                                font-size: 70px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 60px 3vw 70px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title-container {
                margin: 0;

                .title {

                    &::after {
                        width: 40px;
                    }
                }
            }
        }
    }
}


@media (max-width: 1026px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 50px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 50px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 30px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 0;

                .theStep {

                    .picture {
                        width: 210px;
                        height: 210px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 40px 3vw 50px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap !important;
            align-items: center;

            .imgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
                width: 100%;
                margin-top: 30px;

                .picture {
                    width: 400px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 50px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: flex-start;

                    .category {
                        margin-left: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: flex-start;
                        padding: 30px;

                        .categoryTitle {
                            margin-top: 40px;
                            margin-bottom: 50px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 70px;
                                height: 70px;
                                font-size: 70px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 993px) {

    .service {

        .ourCadaster {
            height: auto;
            max-width: 100%;
            overflow: hidden;
            padding: 20px 3vw 50px 3vw;
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start;
            align-items: center;

            .imgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;

                .picture {
                    width: 500px;
                    height: auto;
                }
            }
        }
    }
}


@media (max-width: 811px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 50px;

                .theStep {

                    .picture {
                        width: 350px;
                        height: 350px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            padding: 20px 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .imgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;

                .picture {
                    width: 500px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: flex-start;

                    .category {
                        margin-left: 25px;

                        .categoryTitle {
                            margin-top: 80px;
                            margin-bottom: 30px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 770px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 50px;

                .theStep {

                    .picture {
                        width: 350px;
                        height: 350px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 0 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
                width: 100%;

                .picture {
                    width: 500px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: flex-start;

                    .category {
                        margin-left: 25px;

                        .categoryTitle {
                            margin-top: 80px;
                            margin-bottom: 30px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 541px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2.5vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 30px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 20px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {
                margin-top: 30px;

                .theStep {

                    .picture {
                        width: 250px;
                        height: 250px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 20px 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;

                .picture {
                    width: 430px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: flex-start;

                    .category {
                        padding: 0;

                        .categoryTitle {
                            margin-top: 80px;
                            margin-bottom: 30px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 20px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 415px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 56% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 25px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 30px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2.5vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 20px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 15px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {

                .theStep {

                    .picture {
                        width: 200px;
                        height: 200px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 10px 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;

                .picture {
                    width: 340px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 20px;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {
                    display: flex;
                    justify-content: flex-start;

                    .category {
                        padding: 0;
                        margin: 0;

                        .categoryTitle {
                            margin-top: 60px;
                            margin-bottom: 30px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 10px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 376px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 56% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 25px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 27px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2.5vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 20px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 15px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {

                .theStep {

                    .picture {
                        width: 180px;
                        height: 180px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin: 0;
            padding: 0 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;

                .picture {
                    width: 300px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {

                    .category {
                        padding: 0;
                        margin: 0;

                        .categoryTitle {
                            margin-top: 60px;
                            margin-bottom: 30px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 10px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 321px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 56% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 25px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 25px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2.5vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 20px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 15px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {

                .theStep {

                    .picture {
                        width: 180px;
                        height: 180px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin-top: 0;
            padding: 0 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                width: 100%;
                height: auto;

                .picture {
                    width: 260px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {

                    .category {
                        padding: 0;
                        margin: 0;

                        .categoryTitle {
                            margin-top: 60px;
                            margin-bottom: 25px;
                            margin-left: 10px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 10px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 280px) {

    .services {
        overflow: hidden;

        .servicesHeader {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 56% 47%;

            .title-container {
                margin-top: 180px;

                .title {
                    margin-bottom: 25px;

                    &::after {
                        width: 30px;
                    }
                }

                .subtitle {
                    line-height: 25px;
                }
            }
        }

        .ourServices {
            height: auto;
            padding: 40px 3vw 50px 3vw;

            .all-services {

                .wrapper {
                    padding: 2vh 2vw;

                    .btn {

                        .imgFramer {

                            .overlayer {

                                .title {
                                    margin-left: 20px;

                                    &::after {
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .howItWorks {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 15px;

                .theTitle {

                    &::after {
                        width: 30px;
                    }
                }
            }

            .wrapper {

                .theStep {

                    .picture {
                        width: 180px;
                        height: 180px;
                        margin-bottom: 15px;
                    }

                    .title {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .ourCadaster {
            height: auto;
            margin-top: 0;
            padding: 0 3vw 50px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            
            .imgWrapper {
                height: auto;
                width: 100%;

                .picture {
                    width: 260px;
                    height: auto;
                }
            }
        }

        .allServices {
            padding: 40px 3vw 50px 3vw;

            .title-container {
                margin-bottom: 0;
            }

            .entities {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .business,
                .person {

                    .category {
                        padding: 0;
                        margin: 0;

                        .categoryTitle {
                            margin-top: 60px;
                            margin-bottom: 20px;

                            &::after {
                                width: 30px;
                            }
                        }

                        .wrapper {
                            margin-top: 5px;
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .icon {
                                width: 60px;
                                height: 60px;
                                font-size: 60px;
                                padding: 0;
                                margin-right: 10px;
                            }

                            .descr {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}