// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


//Styling

.home {
  background-color: $mainWhite;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .brandLogo {
    color: $mainWhite;
    transform: rotate(-90deg);
    transform-origin: 60% 180%;
    opacity: 0.2;
    position: fixed;
    top: 120px;
    left: 2vw;
    z-index: 100;
  }

  .verticalSlide {
    color: $mainBlack;
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 892px;
    left: -2vw;
    margin: 0;
    padding: 0;
    font-style: italic;
    font-weight: 300;
    transform: rotate(90deg);
    letter-spacing: 3px;
    z-index: 500;

    .slideWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 5px;
      width: 110px;
      height: 25px;
      position: relative;
      overflow: hidden;

      .slideItem {
        position: absolute;
        left: -200px;
        bottom: 45%;
        background-color: $mainBlue !important;
        height: 2px;
        width: 190px;
        background-color: $mainBlack;
        animation: slide 1.5s infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes slide {
        0% {left: -210px;} 
        100% {left: 180px;} 
      }
    }
  }



  //Landing Section

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 1000px;
    padding: $boxPadding;
    background: url(/images/homepage.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    z-index: 500;

    .buttonsContainer {
      --size: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      right: 3vw;
      top: 80px;
      z-index: 500;
      height: var(--size);
      width: var(--size);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background: $mainBlack;
      opacity: 0.5 !important;
      border-radius: 3px;
      box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
      -moz-box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
      -webkit-box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
      transition: 
        width 0.2s ease-in-out, 
        opacity 0.2s ease;
  
      .globeIcon {
        color: $mainWhite;
        position: absolute;
        font-size: 22px;
        opacity: 1;
        transition: opacity 0.2s ease;
      }
  
      .sq {
        border: none;
        background: transparent;
        color: $mainYellow;
        padding: 4px 10px;
        border-left: solid 1pxrgba(255, 255, 255, 0.2);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
  
        &:focus {
          outline: none;
        }

        &.langActive {
          color: $mainWhite;
          font-weight: 700;
          text-decoration: underline;
          pointer-events: none !important;
        }
      }
      
      .en {
        border: none;
        background: transparent;
        color: $mainYellow;
        padding: 4px 10px;
        border-right: solid 1px rgba(255, 255, 255, 0.2);
        font-size: 15px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
  
        &:focus {
          outline: none;
        }

        &.langActive {
          color: $mainWhite;
          font-weight: 700;
          text-decoration: underline;
          pointer-events: none !important;
        }
      }
  
      &:focus,
      &:hover {
        width: calc(var(--size) + 45px);
        opacity: 1 !important;
        transition: width 0.2s ease-in-out, opacity 0.2s ease;
  
        .globeIcon {
          opacity: 0;
          transition: opacity 0.2s ease;
        }
  
        .en, .sq {
          opacity: 1;
          transition: opacity 0.2s ease;
          pointer-events: all;
          transition-delay: 0.05s;
        }
      }
    }

    .landingPage {
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .mainTitle {
        margin-bottom: 40px;
        color: $mainBlack;
        font-size: 48px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &::after {
          content: '';
          margin-top: 10px;
          background: $blackHue40;
          width: 40px;
          height: 3px;
        }
      }

      .quoteButton {
        background-color: transparent !important;
        color: $mainBlue !important;
        font-size: 20px;
        font-weight: 700;
        padding-right: 30px;
        position: relative;

        .arrow {
          position: absolute;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-end;
          top: 50%;
          right: 0px;
          background-color: $mainBlue;
          width: 20px;
          height: 2px;
          border-radius: 2px;
          opacity: 1;
          transition: transform 0.3s ease-in-out;


          &::before {
            content: '';
            position: absolute;
            background-color: $mainBlue;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: top right;
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;
          }

          &::after {
            content: '';
            position: absolute;
            background-color: $mainBlue;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: bottom right;
            transform: rotate(-45deg);
            transition: transform 0.3s ease-in-out;
          }
        }

        &:focus-within,
        &:hover {

          .arrow {
            transform: translateX(8px);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }



  //Services Section

  .servicesSection {
    width: 100%;
    background-color: $blackHue90;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: url(/images/png/services-section.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    align-items: top;
    padding: 70px 3vw 120px 3vw;
    z-index: 200;
    overflow: hidden;
    
    .title-container {
      background: transparent;
      margin: 0 0 30px 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h3 {
        color: $mainBlack;
        text-align: left;
        position: relative;
        padding: 0;
        margin: 0;
        font-weight: 700;
        margin-bottom: 0.5rem;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          background-color: $blackHue40;
          height: 3px;
          width: 40px;
        }
      }

      .allServices {
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 30px;
        text-align: end;
        font-size: 16px;
        font-weight: 700;
        color: $mainBlack;
        transition: all 0.3s ease;

        .arrow {
          position: absolute;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-end;
          top: 50%;
          right: 0px;
          background-color: $mainOrange;
          width: 20px;
          height: 2px;
          border-radius: 2px;
          opacity: 1;
          transition: transform 0.3s ease-in-out;


          &::before {
            content: '';
            position: absolute;
            background-color: $mainOrange;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: top right;
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;
          }

          &::after {
            content: '';
            position: absolute;
            background-color: $mainOrange;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: bottom right;
            transform: rotate(-45deg);
            transition: transform 0.3s ease-in-out;
          }
        }

        &:focus,
        &:hover {
          color: $mainOrange;

          .arrow {
            transform: translateX(8px);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }

    .wrapper {
      padding: 1.3rem;
      margin: 0;

      button {
        background: transparent;
        outline: none;
        border: none;
        padding: 0;

        .serviceCard {
          position: relative;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding: 0;
          margin: 10px 0 100px 0;
          cursor: pointer;
          border-radius: 3px;
          background-color: transparent;
    
          .imgFramer {
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0;
            width: 100%;
            overflow: hidden;
            border-radius: 3px;
            margin-bottom: 20px;
            box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
            -moz-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
            -webkit-box-shadow: 0px 8px 20px -3px rgba($color: #000000, $alpha: 0.3);
    
            .imgCard {
              width: 100%;
              height: auto;
              opacity: 1;
              transition: all 0.2s ease-in-out;
              transform-origin: center;
            }
          }
    
          .overlayer {
            position: absolute;
            top: 120px;
            background-color: rgba($color: #232323, $alpha: 0.7);
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            border-radius: 3px;
            padding: 35px;
            height: auto;
            width: 100% !important;
            overflow: hidden;
            box-shadow: 0px 8px 20px -3px rgba($color: $mainBlack, $alpha: 0.3);
              -moz-box-shadow: 0px 8px 20px -3px rgba($color: $mainBlack, $alpha: 0.3);
              -webkit-box-shadow: 0px 8px 20px -3px rgba($color: $mainBlack, $alpha: 0.3);
  
            &::before {
              content: '';
              width: 100%;
              height: 0px;
              background-color: $mainOrange;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: all 0.2s ease;
            }
    
            h4 {
              font-weight: 700;
              position: relative;
              margin-bottom: 20px;
              text-align: start;
              font-size: 1.4rem;
              color: $mainWhite;
            }
  
            p {
              text-align: start;
              color: $mainWhite;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              font-style: italic;
            }
            
            .arrow {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: flex-end;
              margin-top: 10px;
              background-color: $mainOrange;
              width: 20px;
              height: 2px;
              border-radius: 2px;
              transition: all 0.2s ease;
      
              &::before {
                content: '';
                position: absolute;
                background-color: $mainOrange;
                width: 10px;
                height: 2px;
                border-radius: 2px;
                transform-origin: top right;
                transform: rotate(0deg);
                transition: all 0.2s ease;
              }
      
              &::after {
                content: '';
                position: absolute;
                background-color: $mainOrange;
                width: 10px;
                height: 2px;
                border-radius: 2px;
                transform-origin: bottom right;
                transform: rotate(0deg);
                transition: all 0.3s ease;
              }
            }
          }
    
          &:focus-within,
          &:hover {
  
            .overlayer {
  
              &::before {
                height: 5px;
              }
            }
    
            .imgFramer {
             
              .imgCard {
                width: 100%;
                transform: scale(1.1);
                opacity: 0.8;
              }
            }
            
            .arrow {
              transform: translateX(5px);
              background-color: $mainOrange;
    
              &::before {
                transform: rotate(45deg);
                background-color: $mainOrange;
              }
    
              &::after {
                transform: rotate(-45deg);
                background-color: $mainOrange;
              }
            }
          }
        }
      }
    }
  }



  //Orage Section

  .orangeSection {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $mainOrange;
    display: flex;
    justify-content: flex-end;
    padding: 100px 3vw 120px 0;
    z-index: 50;

    .home-illustration {
      width: 800px;
      position: absolute;
      bottom: 30px;
      right: -30px;
    }

    .textSection {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100px;
        background-color: $mainWhite;
        top: 0;
        right: 0;
      }

      h1 {
        font-weight: 700;
        color: $mainWhite;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          background-color: $blackHue90;
          height: 3px;
          width: 40px;
        }
      }

      .subtitle {
        color: $mainWhite;
        line-height: 40px;
        margin-top: 30px;
        margin-bottom: 100px;
      }

      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 50px;
        padding: 0;
        
        .icon {
          padding: 0;
          width: auto;
          height: 70px;
          font-size: 70px;
          background: transparent;
        }

        .text {
          margin: 0;
          color: $mainWhite;

          h4 {
            margin-bottom: 20px;
          }

          p {
            font-weight: 400;
            min-height: 48px;
            font-style: italic;
          }
        }
      }
    }
  }


  //Industries Section

  .industries {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: $blackHue90;
    padding: 90px 3vw 80px 3vw;
    z-index: 200;

    .title-container {
      background: transparent;
      margin-bottom: 60px;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      h3 {
        color: $mainBlack;
        font-weight: 400;
        font-style: italic;
        padding: 0;
      }
    }

    .wrapper {
      margin: 20px 0;
      padding: 0;

      .case {
        display: flex;
        justify-content: flex-start;
        margin: 20px 30px;
  
        .icon {
          width: auto;
          height: 100px;
          padding: 0;
          background-color: transparent;
          font-size: 85px;
          color: $mainOrange;
        }
  
        .titleDescr {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          align-items: flex-start;
  
          h4 {
            margin-bottom: 20px;
            font-weight: 700;
            color: $mainBlue;
          }
  
          p {
            font-style: italic;
            margin: 0;
            color: $mainBlack;
          }
        }
      }
    }
  }


  //Projects Section

  .projects {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: $mainBlack;
    padding: 90px 3vw 100px 3vw;
    z-index: 200;

    .title-container {
      background: transparent;
      margin: 0 0 70px 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h3 {
        color: $mainWhite;
        font-weight: 700;
        padding: 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          background-color: $blackHue90;
          height: 3px;
          width: 40px;
        }
      }

      .allProjects {
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 30px;
        text-align: end;
        font-size: 16px;
        font-weight: 700;
        color: $mainWhite;
        transition: all 0.3s ease;

        .arrow {
          position: absolute;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-end;
          top: 50%;
          right: 0px;
          background-color: $mainYellow;
          width: 20px;
          height: 2px;
          border-radius: 2px;
          opacity: 1;
          transition: transform 0.3s ease-in-out;


          &::before {
            content: '';
            position: absolute;
            background-color: $mainYellow;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: top right;
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;
          }

          &::after {
            content: '';
            position: absolute;
            background-color: $mainYellow;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            transform-origin: bottom right;
            transform: rotate(-45deg);
            transition: transform 0.3s ease-in-out;
          }
        }

        &:focus,
        &:hover {
          color: $mainYellow;

          .arrow {
            transform: translateX(8px);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }

    .wrapper {
      margin: 0;
      padding: 20px;

      .button {
        border: none;
        background: transparent;
        outline: none;
        text-align: start;

        .project {
          display: flex;
          flex-direction: row;
          padding: 0;
          position: relative;
          transition: all 0.2s ease;
          
          &::before {
            transform: translateX(6px);
          }
  
          .titleDescr {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            background-color: $blackHue40;
            border-radius: 3px 0 0 3px;
            padding: 30px;
            overflow: hidden;
  
            &::before {
              content: '';
              width: 5px;
              height: 100%;
              background-color: $mainYellow;
              position: absolute;
              top: 0;
              left: -6px;
              transition: all 0.3s ease;
            }
  
            .title {
  
              span {
                color: $blackHue70;
                font-size: 14px;
              }
    
              h4 {
                color: $mainWhite;
                font-weight: 700;
                margin: 10px 0 0 0;
              }
            }
  
            p {
              color: $mainWhite;
              font-style: italic;
              font-weight: 300;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
  
            .date {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              text-align: end !important;
  
              span {
                color: $blackHue70;
                font-size: 14px;
              }
            }
  
            .arrow {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: flex-end;
              margin-top: 10px;
              background-color: $mainYellow;
              width: 20px;
              height: 2px;
              border-radius: 2px;
              transition: all 0.2s ease;
      
              &::before {
                content: '';
                position: absolute;
                background-color: $mainYellow;
                width: 10px;
                height: 2px;
                border-radius: 2px;
                transform-origin: top right;
                transform: rotate(0deg);
                transition: all 0.2s ease;
              }
      
              &::after {
                content: '';
                position: absolute;
                background-color: $mainYellow;
                width: 10px;
                height: 2px;
                border-radius: 2px;
                transform-origin: bottom right;
                transform: rotate(0deg);
                transition: all 0.3s ease;
              }
            }
          }
  
          .projectImg {
            width: 100%;
            height: 300px;
            padding: 0;
            overflow: hidden;
            position: relative;
            border-radius: 0 3px 3px 0;
  
            img {
              height: 300px;
              width: auto;
              position: absolute;
              left: -20%;
              transition: all 0.3s ease;
            }
          }
  
          &:focus-within,
          &:hover {
  
             .titleDescr {
  
              &::before {
                transform: translateX(6px);
              }
            }
  
            .projectImg {
  
              img {
                transform: scale(1.1);
                opacity: 0.7;
              }
            }
            
            .arrow {
              transform: translateX(5px);
              background-color: $mainYellow;
    
              &::before {
                transform: rotate(45deg);
                background-color: $mainYellow;
              }
    
              &::after {
                transform: rotate(-45deg);
                background-color: $mainYellow;
              }
            }
          }
        }
      }
    }
  }


  //Yellow Section

  .yellowSection {
    height: auto;
    background-color: $mainYellow;
    display: flex;
    justify-content: flex-end;
    padding: 100px 3vw 100px 0;
    z-index: 50;

    .textSection {
      position: relative;

      .globe-cartoon {
        position: absolute;
        bottom: 0;
        right: 3vw;
        width: 700px;
        height: auto;
      }

      &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100px;
        background-color: $mainBlack;
        top: 0;
        right: 0;
      }
      
      .intro {
        color: $mainBlack;
        padding: 0;
        letter-spacing: 1px;
        line-height: 40px;
      }
  
      .partners {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 180px;
  
        .partners-title {
          color: $mainBlack;
          font-weight: 400;
          font-style: italic;
          text-align: start;
          margin-top: 6vh;
          letter-spacing: 2px;
        }
    
        .partnerLogos {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 0;
          margin-top: 20px;
          
          .logo {
            width: 100px;
            height: auto !important;
            margin: 10px 3vw 10px 3vw;
          }
        }
      }
    }

    .whyUs {
      background: transparent;
      margin: 0 0 80px 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 700;
        color: $mainBlack;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          background-color: $mainBlack;
          height: 3px;
          width: 40px;
        }
      }
    }

    .reasons {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      .reason {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin: 20px 0;
        padding: 0;

        .icon {
          width: auto;
          height: 100px;
          font-size: 90px;
          color: $mainBlack;
        }

        .label {
          color: $mainBlack;
          font-weight: 400;
          margin: 0;
          padding: 0 20px 15px 20px;
          text-align: left;
        }
      }

      .aboutBtn {
        padding: 0;
        margin: 70px 0 0 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
  
        .button {
          position: relative;
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 30px;
          text-align: start;
          font-size: 20px;
          font-weight: 700;
          color: $mainBlue;
          transition: all 0.2s ease;
  
          .arrow {
            position: absolute;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-end;
            top: 50%;
            right: 0px;
            background-color: $mainBlue;
            width: 22px;
            height: 2px;
            border-radius: 2px;
            opacity: 1;
            transition: transform 0.3s ease-in-out;
    
    
            &::before {
              content: '';
              position: absolute;
              background-color: $mainBlue;
              width: 10px;
              height: 2px;
              border-radius: 2px;
              transform-origin: top right;
              transform: rotate(45deg);
            }
    
            &::after {
              content: '';
              position: absolute;
              background-color: $mainBlue;
              width: 10px;
              height: 2px;
              border-radius: 2px;
              transform-origin: bottom right;
              transform: rotate(-45deg);
            }
          }

          &:focus-within,
          &:hover {
    
            .arrow {
              transform: translateX(8px);
            }
          }
        }
      }
    }
  }



  //Contact Section

  .contact {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $blackHue90;
    z-index: 200;
    padding: 90px 3vw 100px 3vw;
    overflow: hidden !important;

    .yellow-helmet {
      position: absolute;
      bottom: 15%;
      right: 0;
      width: 800px;
    }
    
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      h3 {
        color: $mainBlack;
        font-weight: 700;
        position: relative;
        text-align: left;
        padding: 0;

        &::after {
          content: '';
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          background-color: $blackHue40;
          height: 3px;
          width: 40px;
        }
      }

      h5 {
        color: $mainBlack;
        font-weight: 300;
        text-align: left;
        padding: 0;
        margin: 20px 0 80px 0;
      }
    }

    .form {
      display: flex;
      justify-content: space-between;
      position: relative;

      .form-container {
        padding: 0 1.5rem;

        .dependers {
          display: flex;
          flex-direction: row;

          .random {
            padding: 0 1rem 0 0;
          }
  
          .business {
            padding: 0 0 0 1rem
          }
        }
  
        hr {
          background-color: $blackHue70;
        }
  
        .split {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          .left {
            padding: 0 1rem 0 0;
          }

          .right {
            padding: 0 0 0 1rem;
          }
        }
  
        .miniGDPR {
          color: $blackHue70;
          font-weight: 300;
          font-style: italic;
        }

        .submitActions {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: auto;

          .buttonContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            #loader {
              width: 22px;
              height: 22px;
              background: transparent;
              font-size: 22px;
              color: $mainBlue;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              transform: rotate(360deg);
              transform-origin: center;
              animation: rotation 1.5s infinite linear;
            }

            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              
              to {
                transfrom: rotate(359deg);
              }
            }

            .submitForm {
              border: none;
              outline: none;
              background: transparent;
              color: $blackHue70;
              padding: 5px 30px 5px 0;
              position: relative;
              font-weight: 700;
              font-size: 22px;
              user-select: none;
  
              .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
                top: 55%;
                right: 0px;
                background-color: $blackHue70;
                width: 20px;
                height: 2px;
                border-radius: 2px;
  
  
                &::before {
                  content: '';
                  position: absolute;
                  background-color: $blackHue70;
                  width: 10px;
                  height: 2px;
                  border-radius: 2px;
                  transform-origin: top right;
                  transform: rotate(45deg);
                }
  
                &::after {
                  content: '';
                  position: absolute;
                  background-color: $blackHue70;
                  width: 10px;
                  height: 2px;
                  border-radius: 2px;
                  transform-origin: bottom right;
                  transform: rotate(-45deg);
                }
              }
            }
  
            .submitForm.active {
              border: none;
              outline: none;
              background: transparent;
              color: $mainBlue;
              padding: 5px 30px 5px 0;
              position: relative;
              font-weight: 700;
              font-size: 22px;
              opacity: 1;
              transition: opacity 0.3s ease;
              transition-delay: 0.25s;
  
              .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
                top: 55%;
                right: 0px;
                background-color: $mainBlue;
                width: 20px;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                transition: transform 0.3s ease-in-out;
  
  
                &::before {
                  content: '';
                  position: absolute;
                  background-color: $mainBlue;
                  width: 10px;
                  height: 2px;
                  border-radius: 2px;
                  transform-origin: top right;
                  transform: rotate(45deg);
                  transition: transform 0.3s ease-in-out;
                }
  
                &::after {
                  content: '';
                  position: absolute;
                  background-color: $mainBlue;
                  width: 10px;
                  height: 2px;
                  border-radius: 2px;
                  transform-origin: bottom right;
                  transform: rotate(-45deg);
                  transition: transform 0.3s ease-in-out;
                }
              }
  
              &:hover {
  
                .arrow {
                  transform: translateX(8px);
                  transition: transform 0.3s ease-in-out;
                }
              }
            }
          }

          .successMessage {
            margin: 10px 0 0 0;
            padding: 0;
            color: green;
            font-weight: 400;
            font-style: italic;
          }
        }
      }
    }
  }
}
