// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;
$sectionPadding: 70px 3vw 70px 0;
$imgPadding: 0;


//Styling


.projects {
    position: relative;
    background: transparent;
    overflow: hidden;


    .projectsHeader {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        height: 600px;
        padding: $boxPadding;
        background: url(/images/projectsHeader.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0%;
        z-index: 500;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;

            #ourStory {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                margin-bottom: 30px;
                
                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }

            #theStory {
                font-weight: 300;
                line-height: 30px;
                color: $mainWhite;
            }
        }
    }


    .siu {
        position: relative;
        background-color: $blackHue90;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
    
            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                height: auto;
                margin-bottom: 5vh;
    
                .element {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0;
                    margin-right: 70px;
    
                    .icon {
                        font-size: 35px;
                        color: $blackHue40;
                        margin-right: 10px;
                    }
                    .subject {
                        font-weight: 700;
                        font-style: italic;
                        color: $mainBlack;
                        margin: 0;
                    }
                }
            }

            .bottom-line {
                background-color: $blackHue70;
                width: 100%;
                height: 1px;
                margin-bottom: 5vh;
            }

            .moreBtn {
                position: relative;
                border: none;
                outline: none;
                background: transparent;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 30px;
                padding-left: 0;
                text-align: start;
                font-size: 18px;
                font-weight: 700;
                color: $mainBlack;
                transition: all 0.3s ease;

                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 0px;
                    background-color: $mainBlue;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
            
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
                    color: $mainBlue;
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }


    .expropriation {
        position: relative;
        background-color: $yellowHue10;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
    
            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                height: auto;
                margin-bottom: 5vh;
    
                .element {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0;
                    margin-right: 70px;
    
                    .icon {
                        font-size: 35px;
                        color: $blackHue40;
                        margin-right: 10px;
                    }
    
                    .subject {
                        font-weight: 700;
                        font-style: italic;
                        color: $mainBlack;
                        margin: 0;
                    }
                }
            }

            .bottom-line {
                background-color: $blackHue70;
                width: 100%;
                height: 1px;
                margin-bottom: 5vh;
            }

            .moreBtn {
                position: relative;
                border: none;
                outline: none;
                background: transparent;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 30px;
                padding-left: 0;
                text-align: start;
                font-size: 18px;
                font-weight: 700;
                color: $mainBlack;
                transition: all 0.3s ease;

                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 0px;
                    background-color: $mainBlue;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
            
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
                    color: $mainBlue;
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }


    .migration {
        position: relative;
        background-color: $blackHue90;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: $sectionPadding;
        
        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            height: auto;
            margin-bottom: 5vh;

            .element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin-right: 70px;

                .icon {
                    font-size: 35px;
                    color: $blackHue40;
                    margin-right: 10px;
                }

                .subject {
                    font-weight: 700;
                    font-style: italic;
                    color: $mainBlack;
                    margin: 0;
                }
            }
        }

        .bottom-line {
            background-color: $blackHue70;
            width: 100%;
            height: 1px;
            margin-bottom: 5vh;
        }

        .moreBtn {
            position: relative;
            border: none;
            outline: none;
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 30px;
            padding-left: 0;
            text-align: start;
            font-size: 18px;
            font-weight: 700;
            color: $mainBlack;
            transition: all 0.3s ease;

            .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
                top: 50%;
                right: 0px;
                background-color: $mainBlue;
                width: 20px;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                transition: transform 0.3s ease-in-out;
      
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: top right;
                    transform: rotate(45deg);
                    transition: transform 0.3s ease-in-out;
                }
        
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: bottom right;
                    transform: rotate(-45deg);
                    transition: transform 0.3s ease-in-out;
                }
            }
      
            &:focus-within,
            &:hover {
                color: $mainBlue;

                .arrow {
                    transform: translateX(8px);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }


    .trackMarking {
        position: relative;
        background-color: $yellowHue10;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
    
            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                height: auto;
                margin-bottom: 5vh;
    
                .element {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0;
                    margin-right: 70px;
    
                    .icon {
                        font-size: 35px;
                        color: $blackHue40;
                        margin-right: 10px;
                    }
    
                    .subject {
                        font-weight: 700;
                        font-style: italic;
                        color: $mainBlack;
                        margin: 0;
                    }
                }
            }

            .bottom-line {
                background-color: $blackHue70;
                width: 100%;
                height: 1px;
                margin-bottom: 5vh;
            }
    
            .moreBtn {
                position: relative;
                border: none;
                outline: none;
                background: transparent;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 30px;
                padding-left: 0;
                text-align: start;
                font-size: 18px;
                font-weight: 700;
                color: $mainBlack;
                transition: all 0.3s ease;
    
                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 0px;
                    background-color: $mainBlue;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
            
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
                    color: $mainBlue;
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }


    .topoSurvey {
        position: relative;
        background-color: $blackHue90;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            height: auto;
            margin-bottom: 5vh;

            .element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin-right: 70px;

                .icon {
                    font-size: 35px;
                    color: $blackHue40;
                    margin-right: 10px;
                }

                .icon-dahlem {
                    font-size: 50px !important;
                }

                .subject {
                    font-weight: 700;
                    font-style: italic;
                    color: $mainBlack;
                    margin: 0;
                }
            }
        }

        .bottom-line {
            background-color: $blackHue70;
            width: 100%;
            height: 1px;
            margin-bottom: 5vh;
        }

        .moreBtn {
            position: relative;
            border: none;
            outline: none;
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 30px;
            padding-left: 0;
            text-align: start;
            font-size: 18px;
            font-weight: 700;
            color: $mainBlack;
            transition: all 0.3s ease;

            .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
                top: 50%;
                right: 0px;
                background-color: $mainBlue;
                width: 20px;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                transition: transform 0.3s ease-in-out;
      
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: top right;
                    transform: rotate(45deg);
                    transition: transform 0.3s ease-in-out;
                }
        
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: bottom right;
                    transform: rotate(-45deg);
                    transition: transform 0.3s ease-in-out;
                }
            }
      
            &:focus-within,
            &:hover {
                color: $mainBlue;

                .arrow {
                    transform: translateX(8px);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }


    .geoSurveying {
        position: relative;
        background-color: $yellowHue10;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
    
            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                height: auto;
                margin-bottom: 5vh;
    
                .element {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0;
                    margin-right: 70px;
    
                    .icon {
                        font-size: 35px;
                        color: $blackHue40;
                        margin-right: 10px;
                    }
    
                    .subject {
                        font-weight: 700;
                        font-style: italic;
                        color: $mainBlack;
                        margin: 0;
                    }
                }
            }

            .bottom-line {
                background-color: $blackHue70;
                width: 100%;
                height: 1px;
                margin-bottom: 5vh;
            }
    
            .moreBtn {
                position: relative;
                border: none;
                outline: none;
                background: transparent;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 30px;
                padding-left: 0;
                text-align: start;
                font-size: 18px;
                font-weight: 700;
                color: $mainBlack;
                transition: all 0.3s ease;
    
                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 0px;
                    background-color: $mainBlue;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
            
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlue;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
                    color: $mainBlue;
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }


    .streetProjects {
        position: relative;
        background-color: $blackHue90;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: $sectionPadding;

        .imgContainer {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $imgPadding;
            
            .picture {
                width: 550px;
                height: auto;
            }
        }

        .text-wrapper {
            height: auto;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title-container {
                padding: 0;
                margin-bottom: 4vh;
    
                .title {
                    position: relative;
                    font-weight: 700;
                    color: $mainBlack;
                    margin-bottom: 40px;
    
                    &::after {
                        content: '';
                        position: absolute;
                        background: $blackHue40;
                        bottom: -0.5rem;
                        left: 0;
                        width: 40px;
                        height: 3px;
                    }
                }
    
                .descr {
                    font-weight: 300;
                    color: $mainBlack;
                }
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            height: auto;
            margin-bottom: 5vh;

            .element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin-right: 70px;

                .icon {
                    font-size: 35px;
                    color: $blackHue40;
                    margin-right: 10px;
                }

                .icon-lika {
                    font-size: 50px !important;
                }

                .subject {
                    font-weight: 700;
                    font-style: italic;
                    color: $mainBlack;
                    margin: 0;
                }
            }
        }

        .bottom-line {
            background-color: $blackHue70;
            width: 100%;
            height: 1px;
            margin-bottom: 5vh;
        }

        .moreBtn {
            position: relative;
            border: none;
            outline: none;
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 30px;
            padding-left: 0;
            text-align: start;
            font-size: 18px;
            font-weight: 700;
            color: $mainBlack;
            transition: all 0.3s ease;

            .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
                top: 50%;
                right: 0px;
                background-color: $mainBlue;
                width: 20px;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                transition: transform 0.3s ease-in-out;
      
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: top right;
                    transform: rotate(45deg);
                    transition: transform 0.3s ease-in-out;
                }
        
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: bottom right;
                    transform: rotate(-45deg);
                    transition: transform 0.3s ease-in-out;
                }
            }
      
            &:focus-within,
            &:hover {
                color: $mainBlue;

                .arrow {
                    transform: translateX(8px);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }


    .contactUs {
        width: 100%;
        height: auto;
        background-color: $mainBlack;
        padding: 100px 3vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .title {
                font-weight: 700;
                position: relative;
                color: $mainWhite;
                position: relative;
                margin-bottom: 20px;

                &::after {
                    content: '';
                    position: absolute;
                    background: $blackHue90;
                    bottom: -0.5rem;
                    left: 0;
                    width: 40px;
                    height: 3px;
                }
            }
    
            .subtitle {
                color: $mainWhite;
                font-weight: 300;
                font-style: italic;
            }
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .contact {
                position: relative;
                font-size: 18px;
                font-weight: 700;
                padding: 10px 55px 10px 30px;
                border-radius: 3px;
                color: $mainBlack;
                background-color: $mainYellow;
                transition: all 0.2s ease;
                
                .arrow {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 50%;
                    right: 20px;
                    background-color: $mainBlack;
                    width: 20px;
                    height: 2px;
                    border-radius: 2px;
                    opacity: 1;
                    transition: transform 0.3s ease-in-out;
          
          
                    &::before {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: top right;
                        transform: rotate(45deg);
                        transition: transform 0.3s ease-in-out;
                    }
          
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: $mainBlack;
                        width: 10px;
                        height: 2px;
                        border-radius: 2px;
                        transform-origin: bottom right;
                        transform: rotate(-45deg);
                        transition: transform 0.3s ease-in-out;
                    }
                }
          
                &:focus-within,
                &:hover {
    
                    .arrow {
                        transform: translateX(8px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }

    #divider {
        width: 100%;
        padding: $boxPadding;
        background-color: $mainBlack !important;
        z-index: 700;

        hr {
            background-color: $blackHue90;
            margin: 0;
        }
    }
}