$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


@media (max-width: 1200px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 50px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 500px; 
                }
            }

            .text-wrapper {

                .title-container {
                    margin-bottom: 1vh;

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 50px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 500px; 
                }
            }

            .text-wrapper {

                .title-container {
                    margin-bottom: 1vh;

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }

        .contactUs {
            padding: 60px 3vw 70px 3vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .title-container {
                margin: 0;

                .title {

                    &::after {
                        width: 40px;
                    }
                }
            }
        }
    }
}


@media (max-width: 1026px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 50px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 400px; 
                }
            }

            .text-wrapper {

                .title-container {
                    margin-bottom: 1vh;

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 50px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 400px; 
                }
            }

            .text-wrapper {

                .title-container {
                    margin-bottom: 1vh;

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 1vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 811px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 450px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 450px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 770px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 450px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 450px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 541px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 400px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 400px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 4vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 85px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 70px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 415px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 30px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 30px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 350px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 3vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 350px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 3vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 376px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 25px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 320px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 3vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 320px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 3vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 321px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 25px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 280px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 20px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 280px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}


@media (max-width: 280px) {

    .projects {
        position: relative;
        overflow: hidden;

        .projectsHeader {
            height: 650px;
            width: 100%;
            background-size: cover;
            background-position: 35% 50%;
            background-repeat: no-repeat;

            .title-container {

                #ourStory {
                    margin-top: 50px;
                    margin-bottom: 20px;

                    &::after {
                        width: 30px;
                    }
                }

                #theStory {
                    line-height: 25px;
                }
            }
        }


        .siu,
        .migration,
        .topoSurvey,
        .streetProjects {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 15px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 250px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }


        .expropriation,
        .trackMarking,
        .geoSurveying {
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: 15px 3vw 60px 3vw;

            .imgContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 0;

                .picture {
                   height: auto;
                   width: 250px; 
                }
            }

            .text-wrapper {

                .title-container {

                    .title {
                        margin-bottom: 25px;

                        &::after {
                            width: 30px;
                        }
                    }
                }

                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2vh;

                    .element {
                        width: 100% !important;
                        margin-bottom: 20px;

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                .bottom-line {
                    margin-bottom: 2vh;
                }
            }
        }

        .contactUs {
            padding: 40px 3vw 65px 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-container {
                margin-bottom: 50px;

                .title {

                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }
}