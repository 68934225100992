// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


//Styling

.modalWrapper {
    box-sizing: border-box;
    padding: 0;
    margin-top: 1vh !important;
    background-color: $mainWhite;
    width: 1100px !important;
    height: auto;
    z-index: 1100;
    border-radius: 10px;
    overflow: hidden;

    .modalHeader {
        box-sizing: border-box;
        width: 100%;
        height: 600px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;

        .img {
            width: 100%;
            height: auto;
            margin-top: -10px;
        }

        .cadasterImg {
            width: 145%;
            height: auto;
            margin-top: 0;
            margin-left: -200px;
        }

        .expertiseImg {
            margin: 0;
            width: 115%;
            height: auto;
        }

        .aerialSurvey {
            width: 107%;
            height: auto;
        }

        .cartographyImg {
            width: 120%;
            height: auto;
            margin-top: 0;
            margin-left: -150px;
        }

        .xButton {
            border: none;
            background: transparent;
            outline: none;
            position: absolute;
            top: 15px;
            right: 20px;
            color: $mainYellow;
            font-size: 30px !important;
            margin: 10px 20px;
            text-shadow: 0px 0px 3px 0px rgba($color: $mainBlack, $alpha: 0.7);
            transition: all 0.2s ease;

            &:hover {
                color: $mainBlue;
            }
        }
    }

    .modalBody {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 3vh 3vw 0 3vw;
        background-color: $blackHue90;

        .title {
            font-weight: 700;
            margin-bottom: 40px;
            color: $mainBlack;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -0.5rem;
                left: 0;
                background-color: $mainBlack;
                height: 3px;
                width: 40px;
            }
        }

        .descr {
            font-size: 20px;
            color: $mainBlack;

            #siuLink {
                color: $mainBlue;
            }
        }
        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 2vh 0 3vh 0;
            height: auto;

            .element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin-right: 70px;

                .icon {
                    font-size: 30px;
                    color: $blackHue40;
                    margin-right: 10px;
                }

                .icon-lika,
                .icon-dahlem {
                    font-size: 50px !important;
                }

                .subject {
                    font-weight: 400;
                    font-size: 18px;
                    font-style: italic;
                    color: $mainBlack;
                    margin: 0;
                }
            }
        }

        .bottom-line {
            background-color: $blackHue70;
            width: 100%;
            height: 1px;
        }
    }

    .modalFooter {
        display: flex;
        justify-content: flex-start;
        padding: 3vh 3vw 5vh 3vw;
        background-color: $blackHue90;

        .exit {
            border: none;
            background: transparent;
            outline: none;
            margin: 0;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 30px;
            text-align: start;
            font-size: 18px;
            font-weight: 700;
            color: $mainBlack;
            transition: all 0.3s ease;
    
            .arrow {
                position: absolute;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                top: 50%;
                left: 0px;
                background-color: $mainBlue;
                width: 20px;
                height: 2px;
                border-radius: 2px;
                opacity: 1;
                transition: transform 0.3s ease-in-out;
    
    
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: top left;
                    transform: rotate(-45deg);
                    transition: transform 0.3s ease-in-out;
                }
        
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $mainBlue;
                    width: 10px;
                    height: 2px;
                    border-radius: 2px;
                    transform-origin: bottom left;
                    transform: rotate(45deg);
                    transition: transform 0.3s ease-in-out;
                }
            }
    
            &:focus-within,
            &:hover {
                color: $mainBlue;
    
                .arrow {
                    transform: translateX(-8px);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }
}