$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


@media (max-width: 1400px) {

    .contact {
        padding: 110px 3vw 90px 3vw;
        position: relative;

        .yellow-helmet {
            width: 700px;
            height: auto;
            position: absolute;
            right: 0;
            bottom: 15%;
        }
    }
}


@media (max-width: 1200px) {

    .contact {
        padding: 100px 3vw 90px 3vw;
        position: relative;

        .yellow-helmet {
            position: absolute;
            bottom: 15%;
            right: -2%;
            width: 600px;
        }

        .title-container {

            h5 {
                margin: 20px 0 70px 0;
            }
        }

        .form {
            position: relative;
            
            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .random {
                        padding: 0 1rem 0 0;
                    }
            
                    .business {
                        padding: 0 0 0 1rem
                    }
                }

                .split {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;

                    .left {
                        padding: 0 1rem 0 0;
                    }
        
                    .right {
                        padding: 0 0 0 1rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 1026px) {

    .contact {
        padding: 90px 3vw 50px 3vw;
        position: relative;

        .yellow-helmet {
            position: absolute;
            bottom: 12%;
            right: -2%;
            width: 550px;
        }

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .form {
            position: relative;

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .random {
                        padding: 0 1rem 0 0;
                    }
            
                    .business {
                        padding: 0 0 0 1rem
                    }
                }

                .split {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;

                    .left {
                        padding: 0 1rem 0 0;
                    }
        
                    .right {
                        padding: 0 0 0 1rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 991px) {

    .contact {
        position: relative;

        .yellow-helmet {
            display: none !important;
        }
    }
}


@media (max-width: 811px) {

    .contact {
        padding: 90px 3vw 50px 3vw;

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .yellow-helmet {
            display: none;
        }

        .form {

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .random {
                        padding: 0 1rem 0 0;
                    }
            
                    .business {
                        padding: 0 0 0 1rem
                    }
                }

                .split {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;

                    .left {
                        padding: 0 1rem 0 0;
                    }
        
                    .right {
                        padding: 0 0 0 1rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 415px) {

    .contact {
        padding: 90px 3vw 50px 3vw;

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .yellow-helmet {
            display: none;
        }

        .form {

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: column;

                    .random,
                    .business {
                        padding: 0;
                    }
                }

                .split {
                    display: flex;
                    flex-direction: column;

                    .left,
                    .right {
                        padding: 0;
                    }
                }
            }
        }
    }
}


@media (max-width: 376px) {

    .contact {
        padding: 90px 3vw 50px 3vw;

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .yellow-helmet {
            display: none;
        }

        .form {

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: column;

                    .random,
                    .business {
                        padding: 0;
                    }
                }

                .split {
                    display: flex;
                    flex-direction: column;

                    .left,
                    .right {
                        padding: 0;
                    }
                }
            }
        }
    }
}


@media (max-width: 321px) {

    .contact {
        padding: 80px 3vw 50px 3vw;

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .yellow-helmet {
            display: none;
        }

        .form {

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: column;

                    .random,
                    .business {
                        padding: 0;
                    }
                }

                .split {
                    display: flex;
                    flex-direction: column;

                    .left,
                    .right {
                        padding: 0;
                    }
                }
            }
        }
    }
}


@media (max-width: 280px) {

    .contact {
        padding: 80px 3vw 50px 3vw;

        .title-container {

            h3 {

                &::after {
                    width: 30px;
                }
            }

            h5 {
                margin: 20px 0 50px 0;
            }
        }

        .yellow-helmet {
            display: none;
        }

        .form {

            .form-container {
                padding: 0 0.5rem;

                .dependers {
                    display: flex;
                    flex-direction: column;

                    .random,
                    .business {
                        padding: 0;
                    }
                }

                .split {
                    display: flex;
                    flex-direction: column;

                    .left,
                    .right {
                        padding: 0;
                    }
                }
            }
        }
    }
}