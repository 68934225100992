@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-coorporation:before {
  content: "\e92a";
}
.icon-kk-peja:before {
  content: "\e92b";
}
.icon-agjencia-kadastrale:before {
  content: "\e900";
}
.icon-agriculture:before {
  content: "\e901";
}
.icon-build:before {
  content: "\e902";
  color: #fff;
}
.icon-calendar:before {
  content: "\e903";
}
.icon-cartography:before {
  content: "\e904";
}
.icon-dahlem:before {
  content: "\e905";
}
.icon-devolli:before {
  content: "\e906";
}
.icon-elkos:before {
  content: "\e907";
}
.icon-energy:before {
  content: "\e908";
}
.icon-enivironment:before {
  content: "\e909";
}
.icon-eu:before {
  content: "\e90a";
}
.icon-expand:before {
  content: "\e90b";
  color: #fff;
}
.icon-expertize:before {
  content: "\e90c";
}
.icon-hartografi:before {
  content: "\e90d";
}
.icon-inheritate:before {
  content: "\e90e";
  color: #fff;
}
.icon-kartvertket:before {
  content: "\e90f";
}
.icon-kk-prishtina:before {
  content: "\e910";
}
.icon-kufizim:before {
  content: "\e911";
}
.icon-land-use:before {
  content: "\e912";
}
.icon-leader:before {
  content: "\e913";
}
.icon-legalize:before {
  content: "\e914";
  color: #fff;
}
.icon-legalizim:before {
  content: "\e915";
}
.icon-lika:before {
  content: "\e916";
}
.icon-logo:before {
  content: "\e917";
}
.icon-loop:before {
  content: "\e918";
}
.icon-mabetex:before {
  content: "\e919";
}
.icon-matje-ajrore:before {
  content: "\e91a";
}
.icon-ministria-infastruktures:before {
  content: "\e91b";
}
.icon-modelim-3d:before {
  content: "\e91c";
}
.icon-monitorim:before {
  content: "\e91d";
}
.icon-ndarja-prones:before {
  content: "\e91e";
}
.icon-price:before {
  content: "\e91f";
}
.icon-project-leader:before {
  content: "\e920";
}
.icon-relievim:before {
  content: "\e921";
}
.icon-software:before {
  content: "\e922";
}
.icon-speed:before {
  content: "\e923";
}
.icon-support:before {
  content: "\e924";
}
.icon-technology:before {
  content: "\e925";
}
.icon-telecom:before {
  content: "\e926";
}
.icon-topografi:before {
  content: "\e927";
}
.icon-tradition:before {
  content: "\e928";
}
.icon-transport:before {
  content: "\e929";
}


// Styling presets

$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow:  #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


// Styling

* {
  box-sizing: border-box;
}

a {
  color: $mainBlack;

  &:hover {
    color: $mainBlack;
  }
}

p {
  font-size: 18px;
}

body {
  background: $mainBlack;
  padding: 0;
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 1px;
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  z-index: 3000;
  position: fixed;
  background-color: $blackHue90;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  display: flex;
  transition: opacity 0.5s ease, display 0.3s ease;

  .loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .logo {
      width: auto;
      color: $mainBlue;
      height: auto;
      margin: 1px;
      font-size: 200px;
      display: flex;
      justify-content: center;
    }
  }
}

.loaderContainer.hidden {
  opacity: 0;
  display: none;
}

.scrollToTop {
  --size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mainBlue;
  width: var(--size);
  height: var(--size);
  margin: 0;
  padding: 0;
  border-radius: 3px;
  position: fixed;
  bottom: -50px;
  right: 3vw;
  opacity: 0.7;
  box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
  -moz-box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
  -webkit-box-shadow: 0px 1px 5px 1px rgba($color: #000000, $alpha: 0.3);
  transition: all 0.2s ease;
  z-index: 690;

  .arrow {
    height: var(--size);
    width: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $mainWhite;
  }

  &:focus,
  &:hover {
    opacity: 1;
  }
}



@media (min-width: 991px) {
  .burgerButton {
    display: none;
  }

  #toggleButton {
    display: none;
  }
}


@media (max-width: 1026px) {

  h1 {
    font-size: 40px !important;
  }

  h5 {
    font-size: 22px !important;
  }

  h4 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 30px !important; 
  }

  p {
    font-size: 18px !important;
  }

  button {
    font-size: 20px !important;
  }
}


@media (max-width: 811px) {

  h1 {
    font-size: 36px !important;
  }

  h5 {
    font-size: 20px !important;
  }

  h4 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 28px !important; 
  }

  p {
    font-size: 18px !important;
  }

  button {
    font-size: 20px !important;
  }
}


@media (max-width: 770px) {

  .loaderContainer {

    .loader {
      margin-bottom: 70px;

      .logo {
        font-size: 180px;
      }
    }
  }
}


@media (max-width: 550px) {

  h1 {
    font-size: 32px !important;
  }

  h5 {
    font-size: 20px  !important;
  }

  h4 {
    font-size: 26px  !important;
  }

  h3 {
    font-size: 28px !important; 
  }

  p {
    font-size: 18px  !important;
  }

  button {
    font-size: 20px !important;
  }

  .loaderContainer {

    .loader {

      .logo {
        font-size: 130px;
      }
    }
  }
}


@media (max-width: 415px) {

  h1 {
    font-size: 32px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 28px !important; 
  }

  p {
    font-size: 16px !important;
  }

  button {
    font-size: 20px !important;
  }
}


@media (max-width: 376px) {

  h1 {
    font-size: 30px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 26px !important; 
  }

  p {
    font-size: 16px !important;
  }

  button {
    font-size: 20px !important;
  }

  .loaderContainer {

    .loader {

      .logo {
        font-size: 110px;
      }
    }
  }
}


@media (max-width: 321px) {

  h1 {
    font-size: 28px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 22px !important;
  }

  h3 {
    font-size: 26px !important; 
  }

  p {
    font-size: 16px !important;
  }

  button {
    font-size: 20px !important;
  }
}


@media (max-width: 280px) {

  h1 {
    font-size: 28px !important;
  }

  h2 {
    font-size: 26px !important;
  }

  h5 {
    font-size: 18px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  h3 {
    font-size: 22px !important; 
  }

  p {
    font-size: 16px !important;
  }
}


