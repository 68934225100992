$mainBlack: #232323;
$mainWhite: #ffffff;
$mainBlue: #166DF9;
$mainYellow: #EDCD2C;
$mainOrange: #E27C20;

$blackHue40: #666666;
$blackHue70: #b5b5b5;
$blackHue90: #e5e5e5;

$yellowHue50: #e8d277;
$yellowHue30: #e5d89e;
$yellowHue10: #e2dec9;

$orangeHue55: #dd9864;
$orangeHue30: #dbb397;
$orangeHue10: #d8cbc3;

$blueHue70: #4b91f7;
$blueHue50: #7ab2f4;
$blueHue30: #aacef2;

$boxPadding: 0 3vw 0 3vw;


.toggleBody {
  overflow: hidden !important;
}

.theNav {
  background-color: rgba($color: $mainBlack, $alpha: 0.7);
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: $boxPadding;
  position: fixed;
  z-index: 600;
  box-shadow: 0px 3px 6px 1px rgba($color: #000000, $alpha: 0.1);
    -moz-box-shadow: 0px 3px 6px 1px rgba($color: #000000, $alpha: 0.1);
    -webkit-box-shadow: 0px 3px 6px 1px rgba($color: #000000, $alpha: 0.1);

  .navBrand {
    display: flex;
    height: 49px;
    width: 130px;
    padding: 0;
    position: relative;
    transition: transform 0.3s 1.4s ease-in-out;
    box-sizing: border-box;
    margin: 0;
  }

  .navBrand.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    transition-delay: 0.2s;
  }

  .brandLogo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 130px;
    height: auto;
    z-index: 1000;
  }

  .spacer {
    flex: 1;
  }

  .itemsSection {
    margin-left: 3vw;
  }

  .burgerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .burgerButton {
    position: relative;
    padding: 0;
    margin-top: 8px;
    width: 2em;
    height: 2.3em;
    display: block;
    background-color: transparent;
    outline: none;
    border: none;
    opacity: 1;
    transition: 
      opacity 0.25s 1.1s ease-in-out;

    .line {
      left: 0;
      position: absolute;
      width: 1.7em;
      height: 2px;
      border-radius: 2px;
      background-color: $mainYellow;
      transition: width 0.2s ease-out;

      &::before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 2em;
        height: 2px;
        border-radius: 2px;
        background-color: $mainYellow;
        transition: 
          transform 0.2s ease-in-out, 
          bottom 0.2s 1.4s ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 2em;
        height: 2px;
        border-radius: 2px;
        background-color: $mainYellow;
        transition: 
          transform 0.2s ease-in-out, 
          top 0.2s 1.4s ease-in-out;
      }
    }

    &:hover .line,
    &:focus .line {
      width: 2em;
    }
  }

  .burgerButton.animate {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease;
    transition-delay: 0.4s;
  }

  .burgerButton.animate .line::before {
    bottom: 0;
    transition: bottom 0.3s ease-in-out;
  }

  .burgerButton.animate .line::after {
    top: 0;
    transition: top 0.3s ease-in-out;
  }

  .navWraper {
    height: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;

    .navItems {
      height: 100%;
      width: 47vw;
      padding: 0;
      margin-left: 5vw;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .navItem {
        position: relative;
        width: auto;
        height: 100%;
        padding: 0;
        letter-spacing: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        align-self: center;
        margin: 0 auto;

        .navLink {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-weight: 400;
          color: $mainYellow;
          font-size: 17px;
          transition: all 0.1s ease-in;
          position: relative;
          overflow: hidden;

          &::after {
            content: '';
            width: 100%;
            height: 3px;
            background-color: $mainWhite;
            position: absolute;
            bottom: -4px;
            left: 0;
            transition: all 0.2s ease;
          }

          &:hover {
            color: $mainWhite;

            &::after {
              transform: translateY(-4px);
            }
          }
        }

        .navActive {
          color: $mainWhite;

          &::after {
            content: '';
            width: 100%;
            height: 3px;
            background-color: $mainWhite;
            position: absolute;
            bottom: 0px;
            left: 0;
          }

          &:hover {

            &::after {
              transform: none;
            }
          }
        }
      }
    }

    .phoneNumber {
      color: $mainYellow;
      font-weight: 400;
      padding-top: 4px;
    }
  }
}


//UpDrawer

.upDrawer {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background-color: $blackHue90;
  z-index: 2000;
  top: -100%;
  right: 0;
  transition: 
    transform 0.4s ease-in-out, 
    top 0.4s 0.5s cubic-bezier(.64,.22,.46,.89);

  .upDrawerToolbar {
    width: 100%;
    height: 55px;
    padding: $boxPadding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    top: 0;


    //UpDrawer Nav

    .upDrawerNav.disabled {
      pointer-events: none;
    }

    .navBrand__toggle.hidden {
      position: relative;
      height: 50px;
      width: 130px;
      transform: translateY(-70px);
      pointer-events: none;
      transition-delay: 2s;
      transition: transform 0.3s 0.1s ease-in-out;
    }

    .navBrand__toggle.shown {
      position: relative;
      height: 50px;
      width: 130px;
      transition: transform 0.3s ease-in-out;
      transition-delay: 1s;
    }

    .brandLogo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 130px;
      height: auto;
    }


    //UpDrawer Nav / X Button

    .enable,
    .disable {
      padding: 0;
      margin: 0;
    }

    .disable {
      pointer-events: none;
    }

    .closeSlideButton {
      position: relative;
      padding: 0;
      margin-top: 2px;
      width: 2em;
      height: 2.3em;
      display: block;
      background-color: transparent;
      outline: none;
      border: none;
      opacity: 0;
      transition: 0.3s ease;
      transition-delay: 0.3s;
      
      .line__toggle {
        position: absolute;
        left: 0;
        width: 2em;
        height: 2px;
        border-radius: 2px;
        background-color: $mainBlack;
        transition: 
          background-color 0.2s ease, 
          transform 0.2s 0s ease-in-out;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 2em;
          height: 2px;
          border-radius: 2px;
          background-color: $mainBlack;
          transition: 
            background-color 0.2s ease, 
            transform 0.2s 0s ease-in-out;
        }
      }
    }

    .upDrawerToolbar.disabled {
      pointer-events: none;
    }

    .closeSlideButton.ex {
      opacity: 1;
      transition-delay: 1.2s;
    }

    .closeSlideButton.ex .line__toggle {
      transform: rotate(-45deg);
      transition: 
        transform 0.2s 1.5s ease-in-out;
    }

    .closeSlideButton.ex .line__toggle::before {
      transform: rotate(90deg);
      transition: 
        transform 0.2s 1.5s ease-in-out;
    }
  }

  //UpDrawer / Nav Items

  .upDrawerNavItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    text-decoration: none;
    padding: $boxPadding;
    margin-top: 70px;
    position: relative;

    .navItem {
      padding: 0 5px 0 5px;
      display: flex;
      justify-content: center;
      list-style-type: none;
      width: auto;
      margin: 2vh 0;

      .navLink {
        color: $mainBlack;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 2px;
        transition: all 0.2s ease;
      }

      &:hover .navLink {
        transform: scale(1.1);
      }

      .navLine {
        margin: 3vh 0;
        width: 40vw;
        height: 1px;
        color: $blackHue70;
      }
    }
  }

  .phoneWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .phoneNumber.hidden {
      opacity: 0;
      transform: translateY(-30px);
      pointer-events: none;
      transition:
        transform 0.3s 0s ease, 
        opacity 0.5s 0s ease;
    }

    .phoneNumber.shown {
      color: $blackHue40;
      font-weight: 400;
      text-align: center;
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 0.3s 1.9s ease,
        opacity 0.5s 1.85s ease;
    }
  }

  .upDrawerNavItems.hidden .navItem {
    opacity: 0;
    transform: scale(0.3);
    pointer-events: none;
    transition:
      transform 0.3s 0s ease, 
      opacity 0.5s 0s ease;
  }

  .upDrawerNavItems.shown .navItem:nth-child(1) {
    opacity: 1;
    transform: scale(1.0);
    transition:
      transform 0.3s 1.3s ease,
      opacity 0.5s 1.2s ease;
  }

  .upDrawerNavItems.shown .navItem:nth-child(2) {
    opacity: 1;
    transform: scale(1.0);
    transition:
      transform 0.3s 1.35s ease,
      opacity 0.5s 1.25s ease;
  }

  .upDrawerNavItems.shown .navItem:nth-child(3) {
    opacity: 1;
    transform: scale(1.0);
    transition:
      transform 0.3s 1.45s ease,
      opacity 0.5s 1.35s ease;
  }

  .upDrawerNavItems.shown .navItem:nth-child(4) {
    opacity: 1;
    transform: scale(1.0);
    transition:
      transform 0.3s 1.55s ease,
      opacity 0.5s 1.45s ease;
  }

  .upDrawerNavItems.shown .navItem:nth-child(5) {
    opacity: 1;
    transform: scale(1.0);
    transition:
      transform 0.3s 1.65s ease,
      opacity 0.5s 1.55s ease;
  }
}


//UpDrawer animated

.upDrawer.open {
  top: 0%;
  transition: top 0.4s ease-in-out;
  transition-delay: 0.65s;
  opacity: 1;
}


//Screen ratio responsiveness

@media (min-width: 1200px) {
  .burgerButton {
    display: none !important;
  }

  .spacer {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .navWraper {
    display: none !important;
  }
}